import FiltersIcon from '@kijiji/icons/src/icons/Filters'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'

import { AssistChip } from '@/components/shared/assist-chip'

import { FiltersModal } from './FiltersModal'
import { FilterFloatingButtonContainer, FiltersAppliedCount } from './styled'

export type FilterMobileChipProps = {
  appliedFiltersCount: number
}

export const FilterMobileChip = ({ appliedFiltersCount }: FilterMobileChipProps) => {
  const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false)

  const { t } = useTranslation('srp')

  const handleButtonClick = () => {
    setIsFiltersOpen(true)
  }

  const handleCloseModal = () => {
    setIsFiltersOpen(false)
  }

  return (
    <>
      <FiltersModal isOpen={isFiltersOpen} onClose={handleCloseModal} />

      {!isFiltersOpen && (
        <FilterFloatingButtonContainer>
          <AssistChip
            data-testid="srp-floating-filter-button"
            label={t('filters.title')}
            onClick={handleButtonClick}
          >
            {appliedFiltersCount > 0 ? (
              <FiltersAppliedCount>{` +${appliedFiltersCount}`}</FiltersAppliedCount>
            ) : null}

            <FiltersIcon aria-hidden="true" />
          </AssistChip>
        </FilterFloatingButtonContainer>
      )}
    </>
  )
}
