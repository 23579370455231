import { FLAGS, getFlagsDictionary, shouldShowPriceDrop } from '@/components/srp/search-list/domain'
import { getListingBadgeLabel } from '@/domain/listings/getListingBadgeLabel'
import { BADGE_TYPE_GA_VALUE } from '@/lib/ga/constants/ga'
import { type Listing } from '@/types/search'

/**
 * Gets all the banner types including badges applicable on the listing
 * @param {Listing} item The listing to get the badge values for.
 * @returns
 */
export const getListingFlags = (item: Listing): string[] => {
  const bannerTypesForListing: string[] = []
  const flagsDictionary = getFlagsDictionary(item.flags)

  const badgeValue = getListingBadgeLabel({
    categoryId: item.categoryId,
    categorySpecificBadge: flagsDictionary[FLAGS.CATEGORY_BADGE],
  })

  const hasPriceDrop = shouldShowPriceDrop(
    flagsDictionary[FLAGS.PRICE_DROP],
    item.price.originalPrice?.amount
  )

  const hasHighlight = flagsDictionary[FLAGS.HIGHLIGHT]
  const hasShowcase = flagsDictionary[FLAGS.SHOWCASE]
  const hasVirtualTour = flagsDictionary[FLAGS.VIRTUAL_TOUR]

  if (hasPriceDrop) {
    bannerTypesForListing.push(BADGE_TYPE_GA_VALUE['hasPriceDrop'])
  }

  if (hasHighlight) {
    bannerTypesForListing.push(BADGE_TYPE_GA_VALUE['highlight'])
  }

  if (hasShowcase) {
    bannerTypesForListing.push(BADGE_TYPE_GA_VALUE['showcase'])
  }

  if (hasVirtualTour) {
    bannerTypesForListing.push(BADGE_TYPE_GA_VALUE['virtualTour'])
  }

  if (badgeValue) {
    bannerTypesForListing.push(BADGE_TYPE_GA_VALUE[badgeValue])
  }

  return bannerTypesForListing
}
