import {
  type AppliedAttributeFilter,
  type AppliedDateFilter,
  type AppliedDateRangeFilter,
  type AppliedFilter,
  type AppliedRangeFilter,
  type FilterGroup as FilterGroupType,
  FilterControlType,
} from '@kijiji/generated/graphql-types'
import { useDecision } from '@optimizely/react-sdk'
import { useTranslation } from 'next-i18next'
import { type FC, Fragment } from 'react'

import { ToggleFilter as DeprecatedToggleFilter } from '@/components/shared/deprecated/toggle-filter/ToggleFilter'
import { getLocaleLabelKeyOverride } from '@/domain/filters'
import { useGetSearchResultsData } from '@/hooks/srp/useGetSearchResultsData'
import { FilterKeysEnum, useSearchActions } from '@/hooks/srp/useSearchActions'
import { FEATURE_FLAG } from '@/lib/optimizely'
import {
  isAttributeFilter,
  isDateFilter,
  isDateRangeFilter,
  isRangeFilterWithMinMax,
} from '@/types/search'
import { type NullableFields } from '@/utils/types'

import { DateFilter } from '../filter-types/DateFilter'
import { DateRangeFilter } from '../filter-types/DateRangeFilter'
import { IntegerRangeFilter } from '../filter-types/IntegerRangeFilter'
import { MultiSelectFilter } from '../filter-types/MultiSelectFilter'
import { RadioFilter } from '../filter-types/RadioFilter'
import { ToggleFilter } from '../filter-types/ToggleFilter'
import { FiltersDivider } from '../styled'
import { type FilterRefetchResults, type FilterValuesUnion } from './FiltersAccordion'

export type FilterGroupProps = {
  filterGroup: FilterGroupType
  isMobile?: boolean
  refetch: FilterRefetchResults<FilterValuesUnion>
}

export const FilterGroup: FC<FilterGroupProps> = ({ filterGroup, isMobile, refetch }) => {
  const { t } = useTranslation(['srp'])
  const { data, loadingResults } = useGetSearchResultsData()
  const { getParentFilter, getFilterWithSelectedValues } = useSearchActions()
  const { searchQuery } = data || {}
  const selectedCategoryId = searchQuery?.category?.id || 0

  const [{ enabled: shouldShowNewToggleFilters }] = useDecision(
    FEATURE_FLAG.SUPPORT_NEW_SCHEMA_FOR_TOGGLES_FILTER
  )

  const ToggleFilterComponent = shouldShowNewToggleFilters ? ToggleFilter : DeprecatedToggleFilter

  return filterGroup.filters.map((rawFilter, index) => {
    const key = `${rawFilter.name}-${index}`

    const localeKeyOverride = getLocaleLabelKeyOverride(rawFilter.name, selectedCategoryId)
    const label = localeKeyOverride
      ? t(`filters.${localeKeyOverride}_range.${localeKeyOverride}`)
      : rawFilter.label

    const filter = { ...rawFilter, label }
    const parentFilter = getParentFilter(filter)

    const filterBaseProps = {
      parentFilter,
      isMobile,
      isSrpLoading: loadingResults,
    }

    if (isAttributeFilter(filter)) {
      const attributeProps = {
        ...filterBaseProps,
        filter: getFilterWithSelectedValues(filter),
        refetch: (
          { filterName, ...value }: AppliedFilter & NullableFields<AppliedAttributeFilter>,
          trackingLabel?: string
        ) => refetch(FilterKeysEnum.ATTRIBUTE_FILTERS, filterName, value, trackingLabel),
      }

      return (
        <Fragment key={key}>
          <FiltersDivider />
          {filter.type === FilterControlType.Checkboxes ||
          filter.type === FilterControlType.MultiSelect ? (
            <MultiSelectFilter {...attributeProps} />
          ) : filter.type === FilterControlType.Radio ? (
            <RadioFilter {...attributeProps} />
          ) : filter.type === FilterControlType.Toggles ? (
            <ToggleFilterComponent {...attributeProps} />
          ) : null}
        </Fragment>
      )
    } else if (isRangeFilterWithMinMax(filter)) {
      const rangeProps = {
        ...filterBaseProps,
        filter: getFilterWithSelectedValues(filter),
        refetch: (
          { filterName, ...value }: AppliedFilter & NullableFields<AppliedRangeFilter>,
          trackingLabel?: string
        ) => refetch(FilterKeysEnum.RANGE_FILTERS, filterName, value, trackingLabel),
      }

      return (
        <Fragment key={key}>
          <FiltersDivider />
          <IntegerRangeFilter {...rangeProps} />
        </Fragment>
      )
    } else if (isDateFilter(filter)) {
      const dateProps = {
        ...filterBaseProps,
        filter: getFilterWithSelectedValues(filter),
        refetch: (
          { filterName, ...value }: AppliedFilter & NullableFields<AppliedDateFilter>,
          trackingLabel?: string
        ) => refetch(FilterKeysEnum.DATE_FILTERS, filterName, value, trackingLabel),
      }

      return (
        <Fragment key={key}>
          <FiltersDivider />
          <DateFilter {...dateProps} />
        </Fragment>
      )
    } else if (isDateRangeFilter(filter)) {
      const dateRangeProps = {
        ...filterBaseProps,
        filter: getFilterWithSelectedValues(filter),
        refetch: (
          { filterName, ...value }: AppliedFilter & NullableFields<AppliedDateRangeFilter>,
          trackingLabel?: string
        ) => refetch(FilterKeysEnum.DATE_RANGE_FILTERS, filterName, value, trackingLabel),
      }

      return (
        <Fragment key={key}>
          <FiltersDivider />
          <DateRangeFilter {...dateRangeProps} />
        </Fragment>
      )
    }
  })
}
