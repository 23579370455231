import { AdditionalFlags } from '@kijiji/generated/graphql-types'
import { useTranslation } from 'next-i18next'
import { useTheme } from 'styled-components'

import { useSearchActions } from '@/hooks/srp'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { type Listing } from '@/types/search'
import { BodyText } from '@/ui/atoms/body-text'
import { Spacing } from '@/ui/atoms/spacing'
import { TextLink } from '@/ui/atoms/text-link'

import { LinkCustom } from '../../shared/link-custom/LinkCustom'
import { RenderListingCard } from './RenderListingCard'
import { type SearchListContainerProps, SearchListContainer, TopAdsContainer } from './styled'

type RenderTopAdsProps = {
  topAds: Listing[]
  listingsGap: SearchListContainerProps['gap']
  isICOActive: boolean
  isMobile?: boolean
  totalListings: number
}

export const RenderTopAds = ({
  listingsGap,

  isICOActive,
  isMobile,
  topAds,
  totalListings,
}: RenderTopAdsProps) => {
  const { colors, spacing } = useTheme()
  const { t } = useTranslation(['srp', 'common'])

  const { refetchResults } = useSearchActions()

  const handleSeeAllTopAds = () =>
    refetchResults({
      additionalFlagFilters: [AdditionalFlags.TopAd],
      offset: 0,
      topAdCount: 0,
    })

  if (!topAds.length) return null

  const trackTopAdLearnMoreClick = () => {
    trackEvent({ action: GA_EVENT.TopAdLearnMoreClick })
  }

  return (
    <TopAdsContainer>
      <Spacing mBottom={spacing.default}>
        <BodyText size="medium" color={colors.grey.primary}>
          {t('srp:listing.top_ad.label')}{' '}
          <LinkCustom
            aria-label={t('srp:listing.top_ad.learn_more_long_label')}
            href={t('srp:listing.top_ad.learn_more_link')}
            hasUnderline
            onClick={trackTopAdLearnMoreClick}
            variant="secondary"
          >
            {t('common:labels.learn_more')}
          </LinkCustom>
        </BodyText>
      </Spacing>

      <SearchListContainer data-testid="top-ads-list" gap={listingsGap}>
        {topAds.map((item, index) => (
          <RenderListingCard
            key={item.id}
            index={index}
            isICOActive={isICOActive}
            isMobile={isMobile}
            isTopAd={true}
            item={item}
            totalListings={totalListings}
          />
        ))}
      </SearchListContainer>

      <Spacing mTop={spacing.default}>
        <TextLink
          as="button"
          hasUnderline
          onClick={handleSeeAllTopAds}
          variant="secondary"
          data-testid="see-all-top-ads"
        >
          {t('common:labels.see_all')}
        </TextLink>
      </Spacing>
    </TopAdsContainer>
  )
}
