import styled from 'styled-components'

import { Button } from '@/ui/atoms/button'
import { Flex } from '@/ui/atoms/flex'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'
import { styleFromTypography } from '@/ui/helpers/styleFromTypography'

export const SrpHeaderContainer = styled(Flex)(
  ({ theme }) => `
  margin-top: 0;
  gap: ${theme.spacing.default};
  flex-direction: column;
  
  ${MEDIA_QUERIES(theme).large}{
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-top: ${theme.spacing.default};
    }
  `
)

export const SrpHeaderSearchTitle = styled(HeadlineText)(
  ({ theme }) => `
  align-self: flex-start;
  
  ${MEDIA_QUERIES(theme).large}{
    ${styleFromTypography(theme.typography.headline.xLarge)}
  }
`
)

export const SaveSearchButton = styled(Button)(
  ({ theme }) => `
   svg {
    color: ${theme.colors.purple.light1};
    margin-right: ${theme.spacing.defaultSmall};
    height: ${theme.spacing.large};
    width: auto;
  }
`
)
