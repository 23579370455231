import { isAnyCarsVehiclesCategory, isAnyRealEstateCategory } from '@kijiji/category'

import { type ListingBadgeLabel } from '@/types/listings'

import { isUrgentListing } from './isUrgentListing'

type GetListingBadgeLabelProps = {
  /** Category ID of the listing (not of the search) */
  categoryId: number
  /** Flag value returned by listings API */
  categorySpecificBadge: boolean
}

export const getListingBadgeLabel = ({
  categoryId,
  categorySpecificBadge,
}: GetListingBadgeLabelProps): ListingBadgeLabel | undefined => {
  if (!categorySpecificBadge) return

  // Should show feature badge if is in any real estate category
  if (isAnyRealEstateCategory(categoryId)) return 'featured'

  // Should show urgent badge if is a urgent listing
  if (isUrgentListing({ categoryId, categorySpecificBadge })) return 'urgent'

  /**
   * Should show reduced badge if is in any cars and vehicles
   * category that is not "urgent"
   */
  if (isAnyCarsVehiclesCategory(categoryId)) return 'reduced'
}
