import { isCarsVehiclesExtendedRadiusCategory } from '@kijiji/category'

import { DEFAULT_RADIUS_AUTOS } from '@/constants/location'

import { didUserModifyRadius } from './didUserModifyRadius'

type ShouldBoostSearchRadiusProps = {
  clientCookie?: string
  isRegion: boolean
  radius?: number
  searchCategoryId: number
  serverCookies?: Record<string, unknown>
}

export const shouldBoostSearchRadius = ({
  clientCookie,
  isRegion,
  radius,
  searchCategoryId,
  serverCookies,
}: ShouldBoostSearchRadiusProps): boolean => {
  /**
   * it should only boost radius if user is searching in certain autos categories
   * AND the search is happening on a radius location
   * AND the radius is less than 150km
   * AND the user hasn't manually modified their radius
   * */
  if (
    !isCarsVehiclesExtendedRadiusCategory(searchCategoryId) ||
    isRegion ||
    !radius ||
    radius >= DEFAULT_RADIUS_AUTOS
  ) {
    return false
  }

  return !didUserModifyRadius({ serverCookies, clientCookie })
}
