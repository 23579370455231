import { type ListingFragment } from '@kijiji/generated/graphql-types'
import { ProductJsonLd } from 'next-seo'

import { productCarouselJsonLd, transformListingsToProductSeo } from './productCarouselSeo'

export type ProductCarouselSeoJsonLdProps = {
  id: string
  listings: ListingFragment[]
}

export const ProductCarouselSeoJsonLd = ({ listings, id }: ProductCarouselSeoJsonLdProps) => {
  const productCarouselSeoListings = productCarouselJsonLd(transformListingsToProductSeo(listings))

  return (
    <ProductJsonLd
      type="ItemList"
      productName=""
      data-testid={id}
      itemListElement={productCarouselSeoListings}
    />
  )
}
