import { ICO_LOCATION_ALLOWLIST } from '@/domain/autoCanada'

/**
 * Checks if the given location is in one of the locations where ICO has been
 * launched, based on the location's ID.
 *
 * @returns boolean
 */
export const isICOLaunchedInLocation = (
  locationPaths: { id: number }[] | null | undefined
): boolean => {
  if (locationPaths) {
    for (const location of locationPaths) {
      if (ICO_LOCATION_ALLOWLIST.includes(location.id)) return true
    }
  }
  return false
}
