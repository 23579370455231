import { isCarsTrucks } from '@kijiji/category'
import dynamic from 'next/dynamic'
import { useTranslation } from 'next-i18next'
import { useEffect, useMemo } from 'react'
import { useTheme } from 'styled-components'

import { ImageSeoJsonLd } from '@/components/seo/ImageSeoJsonLd'
import { SRP_LISTING_LIMIT } from '@/constants/pageSettings'
import { getSearchCategoryFromSearchQuery } from '@/domain/category/getSearchCategoryFromSearchQuery'
import { getUserLocationFromSearchQuery } from '@/domain/location/getUserLocationFromSearchQuery'
import { isICOLaunchedInLocation } from '@/domain/location/isICOLaunchedInLocation'
import {
  getExtendedRadius,
  hasExtendedRadiusListings,
  isLowInventorySearch,
  isZeroInventorySearch,
} from '@/domain/lowInventory'
import { getCurrentPageFromPagination } from '@/domain/srp/getCurrentPageFromPaginationData'
import { useGetSearchResultsData } from '@/hooks/srp/useGetSearchResultsData'
import { useSearchListCrossPromoConfig } from '@/hooks/useSearchListCrossPromoConfig'
import { AdSense } from '@/lib/ads/components/adsense'
import { trackEvent } from '@/lib/ga'
import { getSearchPageType } from '@/lib/ga/events/search'
import { type Listing } from '@/types/search'
import { Flex } from '@/ui/atoms/flex'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { Spacing } from '@/ui/atoms/spacing'

import { SrpPagination } from '../pagination'
import { PopularRelatedKeywords } from '../popular-related-keywords/PopularRelatedKeywords'
import { SrpLoading } from '../srp-loading'
import { RenderListingCard } from './RenderListingCard'
import { RenderTopAds } from './RenderTopAds'
import { type SearchListContainerProps, SearchListContainer } from './styled'

const ZeroResultsActionBox = dynamic(
  () => import('@/components/srp/zero-results/').then((mod) => mod.ZeroResultsActionBox),
  { ssr: true } // to prevent layout shift
)

const MiniLeaderboardAdSlot = dynamic(
  () =>
    import('@/lib/ads/ad-slots/srp/MiniLeaderboardAdSlot').then((mod) => mod.MiniLeaderboardAdSlot),
  { ssr: false }
)

const PaginationLinkContainer = dynamic(
  () => import('./styled').then((mod) => mod.PaginationLinkContainer),
  { ssr: false }
)

export type SearchListProps = {
  extendedRadiusListings: Listing[]
  totalPageCount: number
  isMobile?: boolean
}

const autosSearchListContainerGap: SearchListContainerProps['gap'] = {
  small: 'defaultSmall',
  xLarge: 'default',
}

export const SearchList = ({
  extendedRadiusListings = [],
  totalPageCount,
  isMobile,
}: SearchListProps) => {
  const { colors, spacing } = useTheme()

  const { t } = useTranslation(['srp', 'common'])

  const { data, loadingResults: loading } = useGetSearchResultsData()

  const { pagination, searchQuery, results } = data || {}
  const { topAds = [], organic: listings = [] } = results || {}
  const globalKeyword = searchQuery?.keywords || ''
  const { totalCount: totalListings = 0, offset = 0, limit = SRP_LISTING_LIMIT } = pagination || {}

  const currentPage = getCurrentPageFromPagination({ offset, limit })

  const location = getUserLocationFromSearchQuery(searchQuery)
  const category = getSearchCategoryFromSearchQuery(searchQuery?.category)

  const extendedRadius = useMemo(
    () => getExtendedRadius(location?.area?.radius),
    [location?.area?.radius]
  )
  const isZeroSrp = useMemo(() => isZeroInventorySearch(totalListings), [totalListings])
  const isLowSrp = useMemo(() => isLowInventorySearch(totalListings), [totalListings])

  // We are interested in the user's location here, not the location of the listing.
  const isICOActive = useMemo(
    () => isICOLaunchedInLocation(searchQuery?.location?.locationPaths),
    [searchQuery?.location?.locationPaths]
  )

  const { listingsWithCrossPromotion } = useSearchListCrossPromoConfig(listings, category)

  // We want to check the category the user is searching in, not the category
  // of the listing.
  const isCarsTrucksSearch = useMemo(() => isCarsTrucks(category.id), [category.id])
  const listingsGap = isCarsTrucksSearch ? autosSearchListContainerGap : undefined

  const searchPageType = useMemo(() => getSearchPageType(globalKeyword), [globalKeyword])
  useEffect(() => {
    if (isLowSrp) {
      trackEvent({ action: searchPageType, label: 'SuggestionBoxLoad' })
    }
  }, [isLowSrp, searchPageType])

  return (
    <Flex flexDirection="column" gap={spacing.default}>
      <>
        {loading ? (
          <SrpLoading variant="listing-card" />
        ) : (
          <>
            <ImageSeoJsonLd
              listings={listings.concat(topAds)}
              id="srp-listing-card-image-json-ld"
            />

            {/* Provincial Top Ads (PTAs) need to display on ZSRPs */}
            <RenderTopAds
              topAds={topAds}
              isICOActive={isICOActive}
              isMobile={isMobile}
              listingsGap={listingsGap}
              totalListings={totalListings}
            />

            <PopularRelatedKeywords
              categoryId={category.id.toString()}
              keywords={searchQuery?.keywords ?? undefined}
              location={{ id: location.id }}
              locationArea={location.area}
            />
            {isZeroSrp && <ZeroResultsActionBox />}

            {!!listings.length && (
              <SearchListContainer data-testid="srp-search-list" gap={listingsGap}>
                {listings.map((item, index) => (
                  <RenderListingCard
                    key={item.id}
                    item={item}
                    index={index}
                    isICOActive={isICOActive}
                    isCrossPromotionCard={listingsWithCrossPromotion?.includes(item.id)}
                    isMobile={isMobile}
                    totalListings={totalListings}
                  />
                ))}
              </SearchListContainer>
            )}

            {isLowSrp && (
              <>
                {hasExtendedRadiusListings(extendedRadiusListings.length, extendedRadius) && (
                  <>
                    <Spacing mTop="2rem" mBottom="1rem">
                      <HeadlineText
                        color={colors.grey.primary}
                        size="xLarge"
                        weight="medium"
                        as="h2"
                      >
                        {t('srp:extended_listings.title', { extendedRadius })}
                      </HeadlineText>
                    </Spacing>

                    <SearchListContainer data-testid="srp-extended-list" gap={listingsGap}>
                      {extendedRadiusListings.map((item, index) => (
                        <RenderListingCard
                          key={item.id}
                          item={item}
                          index={index}
                          totalListings={extendedRadiusListings.length}
                          isICOActive={isICOActive}
                          isMobile={isMobile}
                        />
                      ))}
                    </SearchListContainer>
                  </>
                )}

                <Spacing mTop="6rem">
                  <ZeroResultsActionBox />
                </Spacing>
              </>
            )}
          </>
        )}
      </>
      {/* Minileaderboard should not appear on zSRP */}
      <AdSense id="AFSBottom">{!!totalListings && <MiniLeaderboardAdSlot />}</AdSense>

      <PaginationLinkContainer justifyContent="center" alignItems="center">
        {totalPageCount > 1 && (
          <SrpPagination selectedIndex={currentPage} totalPageCount={totalPageCount} />
        )}
      </PaginationLinkContainer>
    </Flex>
  )
}
