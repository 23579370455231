import { useRef } from 'react'

import { GPT_COMPONENT_ID } from '@/lib/ads/constants/adSlots'
import { useAdIframe } from '@/lib/ads/hooks/useAdIframe'
import { useScrollAway } from '@/lib/ads/hooks/useScrollAway'

export const useTopLeaderboardAd = () => {
  const ref = useRef<HTMLDivElement>(null)

  // generate a new key when the screen size changes to force a new ad request
  const topLeaderboardKey = GPT_COMPONENT_ID.TOP_LEADERBOARD

  const { handleInViewChange, isSticky, scrollAway } = useScrollAway()
  const { adWidth, rootMargin, stickyOffset } = useAdIframe(ref)

  return {
    scrollAway,
    isSticky,
    topLeaderboardKey,
    adWidth,
    rootMargin,
    stickyOffset,
    handleInViewChange,
    ref,
  }
}
