import { ShowAtOrLarger, ShowWhenSmaller } from '@/components/shared/breakpoint'

import { LoadingBlockContainer } from './LoadingBlockContainer'

export const LoadingSaveSearchToggle = () => {
  return (
    <>
      <ShowWhenSmaller breakpoint="large">
        <LoadingBlockContainer variant="inline" width="30%" height="2.25rem" bottom="1.55rem" />
      </ShowWhenSmaller>
      <ShowAtOrLarger breakpoint="large">
        <LoadingBlockContainer variant="inline" width="30%" height="2.25rem" bottom="0px" />
      </ShowAtOrLarger>
    </>
  )
}
