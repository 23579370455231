import { type ListingFragment, type SearchCategory } from '@kijiji/generated/graphql-types'

import { getCrossPromoConfig } from '../components/srp/search-list/domain'

type UseSearchListCrossPromoConfigReturnType = {
  listingsWithCrossPromotion?: string[]
}
export const CROSS_PROMO_LISTINGS_PER_PAGE = 1

/**
 * `useSearchListCrossPromoConfig` is a custom hook that returns a list of listing ids that applies to a cross promo.
 *
 * @param {ListingFragment[]} listings - An array of ListingFragment objects.
 *
 * @param {SearchCategory} currentCategory - An object representing the current category
 *
 * @returns {number[]} listingsWithCrossPromotion - A list of listing ids that applies to a cross promo.
 *
 * @example
 * const { listingsWithCrossPromotion } = useSearchListCrossPromoConfig(listings, category);
 */
export const useSearchListCrossPromoConfig = (
  listings: ListingFragment[],
  currentCategory: SearchCategory
): UseSearchListCrossPromoConfigReturnType => {
  const listingsWithCrossPromotion = getCrossPromoConfig(
    listings,
    currentCategory,
    CROSS_PROMO_LISTINGS_PER_PAGE
  )

  return { listingsWithCrossPromotion }
}
