import { getSearchTypeWithKeywords } from '@/domain/search/getSearchTypeWithKeywords'

import { type CreateAdsenseChannelParams, createAdsenseChannel } from './createAdsenseChannel'

type generateAdSenseChannelProps = {
  allCategoryIds: string[]
  locationId?: number
  currentPage: number
  keywords: string
  overrides: Partial<CreateAdsenseChannelParams>
}

/**
 * Generate the AdSense channel based on the provided parameters and overrides.
 *
 * @param allCategoryIds - All the category IDs for generating the channel.
 * @param locationId - The location ID for generating the channel.
 * @param currentPage - The current page number.
 * @param keywords - Keywords used in the search query.
 * @param overrides - Optional overrides for the channel parameters.
 * @returns - The generated AdSense channel string, or undefined if not enabled.
 */
export function generateAdSenseChannel({
  allCategoryIds,
  locationId,
  currentPage,
  keywords,
  overrides = {},
}: generateAdSenseChannelProps): string | undefined {
  const {
    expInd,
    pageType,
    l1Catid,
    l2Catid,
    ads,
    page,
    locationId: overrideLocationId,
    expName,
    expGroup,
  } = overrides

  return createAdsenseChannel({
    expInd: expInd,
    os: 'w', // hardcoded to match legacy which is always 'w'
    pageType: pageType || getSearchTypeWithKeywords(keywords),
    l1Catid: l1Catid || allCategoryIds[0],
    l2Catid: l2Catid || allCategoryIds[1],
    ads: ads,
    page: page || currentPage,
    locationId: overrideLocationId || locationId || -1,
    expName: expName,
    expGroup: expGroup,
  })
}
