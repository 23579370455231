import { useOneTimeSavedSearchMutation } from '@kijiji/generated/graphql-types'
import { useSession } from 'next-auth/react'
import { useMemo, useState } from 'react'

import { getUserLocationLabel } from '@/domain/location/getUserLocationLabel'
import { ensureSearchStringStartsWithQuestionMark } from '@/domain/oneTimeSaveSearch'
import { isUserAuthenticated } from '@/domain/user'
import { checkToggle } from '@/utils/featureToggles'
import { sendToLogger } from '@/utils/sendToLogger'

import { useGetSearchKeyword } from './keywords/useGetSearchKeywords'
import { useGetLocation } from './location/useGetLocation'
import { useLocale } from './useLocale'

type UseSrpInViewListingsResponse = {
  handleOneTimeSaveSearch: (props: { inView: boolean; index: number; page: number }) => void
}

export const useOneTimeSaveSearch = (searchString: string): UseSrpInViewListingsResponse => {
  const isOneTimeSaveSearchToggledOn = useMemo(() => checkToggle('isOneTimeSavedSearchEnabled'), [])
  const { status: userStatus } = useSession()
  const { apiLocale } = useLocale()

  const { location } = useGetLocation()
  const { keyword } = useGetSearchKeyword()

  /** Defines if one time save search has already been triggered */
  const [hasBeenTriggered, setHasBeenTriggered] = useState<boolean>(false)
  const [postOneTimeSavedSearch] = useOneTimeSavedSearchMutation()

  /**
   * Perform actions based on intersection observer inView status of listings
   * Should only be triggered when listing is "in view"
   */
  const handleOneTimeSaveSearch = ({
    inView,
    index,
    page,
  }: {
    inView: boolean
    index: number
    page: number
  }) => {
    const address = getUserLocationLabel(apiLocale, location)

    const shouldTriggerOneTimeSaveSearch =
      isOneTimeSaveSearchToggledOn &&
      !hasBeenTriggered &&
      isUserAuthenticated(userStatus) &&
      page === 1 &&
      inView &&
      index === 9 &&
      keyword

    if (!shouldTriggerOneTimeSaveSearch) return

    const searchStringOverride = ensureSearchStringStartsWithQuestionMark(searchString)
    postOneTimeSavedSearch({
      variables: { input: { address, searchQuery: searchStringOverride } },
      onCompleted: () => {
        setHasBeenTriggered(true)
      },
      onError: (err) => {
        // set to true even if the call fails to prevent subsequent calls
        setHasBeenTriggered(true)
        sendToLogger(err, { fingerprint: ['useOneTimeSaveSearch'] })
      },
    })
  }

  return { handleOneTimeSaveSearch }
}
