import { type FC, type ReactNode, useCallback, useState } from 'react'
import { AdvertisingProvider } from 'react-advertising'

import { AdvertisingScripts } from '@/lib/ads/ads-config/srp/AdvertisingScripts'
import { useSrpAds } from '@/lib/ads/ads-config/srp/useSrpAds'
import { prebidConfig } from '@/lib/ads/components/prebid/prebidConfig'

type SearchAdsWrapperProps = {
  children: ReactNode
}

export const SearchAdsWrapper: FC<SearchAdsWrapperProps> = ({ children }) => {
  const [isGptReady, setIsGptReady] = useState(false)
  const { gpt, srpGptConfig } = useSrpAds()

  const handleGptReady = useCallback(() => {
    setIsGptReady(true)
  }, [])

  return (
    <>
      <AdvertisingScripts gpt={gpt} handleGptReady={handleGptReady} />

      <AdvertisingProvider config={isGptReady ? srpGptConfig : null} plugins={prebidConfig}>
        {children}
      </AdvertisingProvider>
    </>
  )
}
