import { type AppliedAttributeFilter, type AttributeFilter } from '@kijiji/generated/graphql-types'
import { type FC, type SyntheticEvent } from 'react'

import { getAttributeFilterValuesCount } from '@/domain/filters'
import { useParentFilter } from '@/hooks/filters/useParentFilter'
import { AccordionItem } from '@/ui/atoms/accordion'
import { FilterChip } from '@/ui/atoms/filter-chip'

import { type FilterProps } from '../FiltersAccordion/FiltersAccordion'
import { FilterCheckboxList } from './FilterCheckboxList'
import { ChipsFilterContainer } from './styled'
import { MAX_CHIP_FILTERS } from './useFieldsVisibility'

export const MultiSelectFilter: FC<FilterProps<AttributeFilter, AppliedAttributeFilter>> = ({
  filter,
  parentFilter,
  isMobile,
  refetch,
}) => {
  const { name, label, selectedValues, values } = filter

  const { filterDescription } = useParentFilter({
    attributeFilter: filter,
    parentFilter,
    resetSelectedValues: () => refetch({ filterName: name, values: null }),
  })

  /**
   * Should render the options as chips if the filter is not of type child and has less than
   * the maximum number of options. Otherwise, the filter options should be rendered as
   * checkboxes.
   */
  const filterOptionsLessThanMax = getAttributeFilterValuesCount(filter) <= MAX_CHIP_FILTERS
  const shouldShowChips = !parentFilter && filterOptionsLessThanMax

  const options = values?.map((field, index) => {
    const canonicalValue = field.value
    const label = field.label
    const canonicalParentValue = `${canonicalValue}-${field.parentValue}`
    const checkBoxId = `${name}-${index}-${
      field.parentValue ? canonicalParentValue : canonicalValue
    }${isMobile ? '-mobile' : ''}`

    return {
      'data-testid': 'checkbox-multiselect-filter',
      id: checkBoxId,
      label,
      onChange: (e: SyntheticEvent<HTMLInputElement>) => {
        const selectedValue = (e.target as HTMLInputElement).value

        const updatedSelectedValues = selectedValues?.includes(selectedValue)
          ? selectedValues?.filter((v) => v !== selectedValue)
          : [...(selectedValues || []), selectedValue]

        const trackingLabel = `${name}=[${updatedSelectedValues.join(', ')}]`

        refetch({ filterName: name, values: updatedSelectedValues }, trackingLabel)
      },
      value: canonicalValue,
      checked: selectedValues?.includes(field.value),
    }
  })

  if (!options?.length) return null

  return (
    <AccordionItem
      description={filterDescription}
      id={name}
      isDisabled={parentFilter && !parentFilter.isSelected}
      key={name}
      title={label || ''}
    >
      {!shouldShowChips ? (
        <FilterCheckboxList filter={filter} options={options} />
      ) : (
        <ChipsFilterContainer data-testid="chips-filter">
          {options.map((item, index) => (
            <FilterChip key={`${item.id}-${index}`} {...item} />
          ))}
        </ChipsFilterContainer>
      )}
    </AccordionItem>
  )
}
