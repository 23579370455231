import { type FilterGroup, type Locale, type SearchCategory } from '@kijiji/generated/graphql-types'

import { ALL_CANADA_LABEL_EN } from '@/constants/location'
import { type ApiLocale } from '@/domain/locale'

import { getMetaCustomOverride } from './getMetaCustomOverride'
import { getMetaDetailsPerCategoryId, getSrpMetadataLegacyFile } from './getSrpLegacyMetadata'
import { getSrpMetaStaticFallback } from './getSrpMetaStaticFallback'

/**
 * Responsible for fetching Title & Description meta data
 * if this function is being used on SSR, a "baseURL" needs to be passed
 *
 * @returns Title and Description for a specific categoryID
 */
export const getMetaTitleDescriptionH1 = ({
  apiLocale,
  category,
  filters,
  keyword,
  location,
  totalListingsCount,
}: {
  apiLocale: ApiLocale
  category: SearchCategory
  filters: FilterGroup[]
  keyword: string | null
  location?: Locale | null
  totalListingsCount: number
}): { title: string; description: string; dynamicH1?: string } => {
  const locationName = location?.[apiLocale] || ALL_CANADA_LABEL_EN
  const categoryName = category.localizedName

  /** If is a SRP it should return the static metadata */
  if (keyword) {
    const staticData = getSrpMetaStaticFallback({ locationName, keyword, apiLocale, categoryName })
    return { title: staticData.title, description: staticData.description }
  }

  /** Check for override data if is an BRP */
  const customOverrideData = getMetaCustomOverride({
    apiLocale,
    filters,
    category,
    locationName,
    totalListingsCount,
  })

  const dynamicTitle = customOverrideData?.title
  const dynamicDescription = customOverrideData?.description
  const dynamicH1 = customOverrideData?.h1

  /** It should prioritize the return of the override data if it returned a value  */
  if (dynamicTitle && dynamicDescription) {
    return { title: dynamicTitle, description: dynamicDescription, dynamicH1 }
  }

  /**
   * [LEGACY] If it is a BRP and
   * If there is no override data, look for the general title + description per category
   * If there is partial data (title or description was returned), it means the rest should be static
   * */
  if (!dynamicTitle && !dynamicDescription && !dynamicH1) {
    const fileData = getSrpMetadataLegacyFile(apiLocale)
    const { title: legacyTitle, description: legacyDescription } = getMetaDetailsPerCategoryId(
      fileData,
      category.id,
      locationName
    )

    if (legacyTitle && legacyDescription) {
      return {
        title: legacyTitle,
        description: legacyDescription,
      }
    }
  }

  /** If any of the meta content (title / description) is missing fallback to the static data */
  const staticMetadata = getSrpMetaStaticFallback({
    apiLocale,
    categoryName,
    keyword,
    locationName,
  })

  return {
    title: dynamicTitle || staticMetadata.title,
    description: dynamicDescription || staticMetadata.description,
    dynamicH1,
  }
}
