import { type TFunction } from 'next-i18next'

import { FILTER_CANONICAL } from '@/constants/search'
import { type ApiLocale, API_LOCALE } from '@/domain/locale'
import { type RangeFilterMinMax } from '@/types/search'
import { formatPrice } from '@/utils/kijiji-common/money'

/**
 * Get filter description from selected price filter
 */
export const getRangeFilterDescription = ({
  apiLocale,
  rangeFilter,
  t,
}: {
  apiLocale: ApiLocale
  rangeFilter: RangeFilterMinMax
  t: TFunction
}): string => {
  const selectedValues = rangeFilter.selectedRangeValues

  if (!selectedValues) return ''

  /** Price range filters needs to be treated differently */
  if (rangeFilter.name === FILTER_CANONICAL.PRICE_RANGE) {
    const from =
      selectedValues.min >= 0
        ? `${t('filters.description.from')} ${formatPrice(selectedValues.min, {
            isFrench: apiLocale === API_LOCALE.fr_CA,
          })}`
        : ''

    /**
     * Should append "- " before the max value only if there is no min
     * ex: - to $10
     */
    const to = selectedValues.max
      ? `${!from ? `- ` : ' '}${t('filters.description.to')} ${formatPrice(selectedValues.max, {
          isFrench: apiLocale === API_LOCALE.fr_CA,
        })}`
      : ''

    return `${from}${to}`
  }

  /** Any other range filter */
  const from = selectedValues.min ? `${t('filters.description.from')} ${selectedValues.min}` : ''
  const to = selectedValues.max
    ? `${!from ? `- ` : ' '}${t('filters.description.to')} ${selectedValues.max}`
    : ''

  return `${from}${to}`
}
