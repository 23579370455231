import { useTranslation } from 'next-i18next'
import { useTheme } from 'styled-components'

import { ROUTE_LOCALE } from '@/domain/locale'
import { useLocale } from '@/hooks/useLocale'
import { Badge } from '@/ui/atoms/badge'
import { BodyText } from '@/ui/atoms/body-text'

/**
 * This method return the label if the ad is provincial top ad, catering french and english version
 * which are different in UX
 */

export const ProvincialTopAdBadge = () => {
  const { t } = useTranslation('srp')
  const { colors } = useTheme()
  const { routeLocale } = useLocale()

  return routeLocale === ROUTE_LOCALE.fr ? (
    <Badge label={t(`listing.top_ad.provincial.badge`)} />
  ) : (
    <BodyText color={colors.grey.light1} data-testid="provincial-top-ad-label" size="small">
      {t(`listing.top_ad.provincial.badge`)}
    </BodyText>
  )
}
