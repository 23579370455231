import { type LanguageKey } from '@/domain/locale'
import {
  adSizesMap,
  GPT_COMPONENT_ID,
  GPT_GLOBAL_PROPERTIES,
  SIZE_MAPPING_NAMES,
} from '@/lib/ads/constants/adSlots'
import { PREBID_SLOT_CONFIGS } from '@/lib/ads/constants/prebid'
import { type AdConfig, type GptTargeting } from '@/lib/ads/types/adConfig'
import {
  createMediaTypes,
  flattenGptArray,
  generateAdUnitPath,
  shouldDisableLazyLoad,
} from '@/lib/ads/utils/globalOptimizationTool'

type Device = 'desktop' | 'mweb'

export const DESKTOP_SLOTS_SRP = [
  GPT_COMPONENT_ID.TOP_LEADERBOARD,
  GPT_COMPONENT_ID.INLINE_1,
  GPT_COMPONENT_ID.INLINE_2,
  GPT_COMPONENT_ID.INLINE_3,
  GPT_COMPONENT_ID.INLINE_4,
  GPT_COMPONENT_ID.MINI_LEADERBOARD,
  GPT_COMPONENT_ID.BASE_LEADERBOARD,
  GPT_COMPONENT_ID.SKYSCRAPER,
]

export const DESKTOP_SLOTS_ZSRP = [
  GPT_COMPONENT_ID.TOP_LEADERBOARD,
  GPT_COMPONENT_ID.BASE_LEADERBOARD,
  GPT_COMPONENT_ID.SKYSCRAPER,
]

export const MOBILE_SLOTS_SRP = [
  GPT_COMPONENT_ID.INLINE_1,
  GPT_COMPONENT_ID.INLINE_2,
  GPT_COMPONENT_ID.INLINE_3,
  GPT_COMPONENT_ID.INLINE_4,
  GPT_COMPONENT_ID.MINI_LEADERBOARD,
  GPT_COMPONENT_ID.BASE_LEADERBOARD,
  GPT_COMPONENT_ID.MWEB_LEADERBOARD,
]

export const MOBILE_SLOTS_ZSRP = [
  GPT_COMPONENT_ID.BASE_LEADERBOARD,
  GPT_COMPONENT_ID.MWEB_LEADERBOARD,
]

const adSlotConfig = {
  mweb: {
    zsrp: MOBILE_SLOTS_ZSRP,
    srp: MOBILE_SLOTS_SRP,
  },
  desktop: {
    zsrp: DESKTOP_SLOTS_ZSRP,
    srp: DESKTOP_SLOTS_SRP,
  },
}

export const SLOT_TARGETING_VALUES = {
  [GPT_COMPONENT_ID.TOP_LEADERBOARD]: { pos: 'top_header' },
  [GPT_COMPONENT_ID.INLINE_1]: { pos: 'bottom' },
  [GPT_COMPONENT_ID.INLINE_2]: { pos: 'bottom2' },
  [GPT_COMPONENT_ID.INLINE_3]: { pos: 'bottom3' },
  [GPT_COMPONENT_ID.INLINE_4]: { pos: 'bottom4' },
  [GPT_COMPONENT_ID.MINI_LEADERBOARD]: {}, // no values for mini leaderboard
  [GPT_COMPONENT_ID.BASE_LEADERBOARD]: { pos: 'base' },
  [GPT_COMPONENT_ID.SKYSCRAPER]: { pos: 'top' },
  [GPT_COMPONENT_ID.MWEB_LEADERBOARD]: { pos: 'top' },
}

const generateAdSlots = (slotIds: string[], device: Device, locale: LanguageKey) => {
  return slotIds.map((slotId: string) => {
    const prebidSlotConfigs = PREBID_SLOT_CONFIGS[slotId]?.[device]?.[locale]

    // inline ids are appended with a number, but the ad sizes & sizeMappings are the same
    const isInlineAd = slotId.includes('inline')
    const adSizes = isInlineAd ? adSizesMap[GPT_COMPONENT_ID.INLINE] : adSizesMap[slotId]
    const sizeMappingName = isInlineAd
      ? SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]
      : SIZE_MAPPING_NAMES[slotId]

    return {
      id: slotId,
      metaData: {},
      ...(shouldDisableLazyLoad(slotId) ? { enableLazyLoad: false } : {}),
      prebid: [{ bids: prebidSlotConfigs, mediaTypes: createMediaTypes(adSizes) }],
      sizeMappingName,
      sizes: adSizes,
      targeting: SLOT_TARGETING_VALUES[slotId],
    }
  })
}

export const createSrpGptConfig = (gptTargetingFromProps: GptTargeting[]) => {
  const flattenedGptArray = flattenGptArray(gptTargetingFromProps)
  const { lang, dv, pagetype } = flattenedGptArray

  const device = dv === 'm' ? 'mweb' : 'desktop'
  const deviceConfig = adSlotConfig[device]

  const simplifiedPageType = pagetype === 'zsrp' ? 'zsrp' : 'srp'
  const slotIds = deviceConfig[simplifiedPageType] || deviceConfig.srp

  const locale = lang === 'fr' ? lang : 'en'

  const srpGptConfig: AdConfig = {
    ...GPT_GLOBAL_PROPERTIES,
    path: generateAdUnitPath(flattenedGptArray),
    targeting: flattenedGptArray,
    slots: generateAdSlots(slotIds, device, locale),
  }

  return srpGptConfig
}
