import styled from 'styled-components'

import { LinkCustom } from '@/components/shared/link-custom/LinkCustom'
import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'
import { ListingImage } from '@/ui/atoms/listing-image'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const TicketListingCardDescription = styled(BodyText)(
  ({ theme }) => `
  padding-top: ${theme.spacing.default};
`
)

export const TicketListingImageBase = styled(ListingImage)(
  ({ theme }) => `
    background: ${theme.colors.white};
    display: none;
    & img {
      object-fit: fill;
    }
    ${MEDIA_QUERIES(theme).medium}{
      display: flex;
    }
  `
)

export const TicketListingFooter = styled(Flex)(
  ({ theme }) => `
    gap: ${theme.spacing.default};
    flex-direction: column;

    & a {
      width: auto !important;
    }

    ${MEDIA_QUERIES(theme).medium}{
      flex-direction: row;
    }
  `
)

// TODO: Remove this class once LinkCustom supports the "secondary" variant
export const TicketListingPolicyButton = styled(LinkCustom)(
  ({ theme }) => `
    background-color: ${theme.colors.white} !important;
    border-color: ${theme.colors.purple.primary} !important;
    color: ${theme.colors.purple.primary} !important;
  `
)
