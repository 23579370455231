import { CATEGORIES, isBuyAndSellCategory } from '@kijiji/category'
import { GetPendingSearchInputDocument, SearchOrigin } from '@kijiji/generated/graphql-types'
import isMobile from 'ismobilejs'
import { type GetServerSideProps } from 'next'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import qs from 'query-string'
import { useEffect, useMemo, useState } from 'react'
import { useTheme } from 'styled-components'

import { ProductCarouselSeoJsonLd } from '@/components/seo/ProductCarouselSeoJsonLd'
import { ErrorBoundary } from '@/components/shared/error-boundary'
import { BaseLayout } from '@/components/shared/layouts/BaseLayout'
import { PageContainer } from '@/components/shared/page-container'
import { RadiusBoostSystemMessage } from '@/components/shared/radius-boost-system-message/RadiusBoostSystemMessage'
import { SrpGrid, SrpHeader, SrpResultsSort } from '@/components/srp'
import { FiltersSidebar } from '@/components/srp/filters/FiltersSidebar'
import { TicketListingCard } from '@/components/srp/listing-card/ticket-listing-card/TicketListingCard'
import { SearchFloatingButtons } from '@/components/srp/search-floating-buttons'
import { SearchList } from '@/components/srp/search-list'
import { SeoMetadata } from '@/components/srp/seo-metadata'
import { SrpBreadcrumb } from '@/components/srp/srp-breadcrumb/SrpBreadcrumb'
import { SrpError } from '@/components/srp/srp-error'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { PAGE_TYPE } from '@/constants/pageTypes'
import { getSearchCategoryFromSearchQuery } from '@/domain/category/getSearchCategoryFromSearchQuery'
import { debugServerTime } from '@/domain/debugServerTiming'
import { initializePageServer } from '@/domain/initializePageServer'
import { getUserLocationFromSearchQuery } from '@/domain/location/getUserLocationFromSearchQuery'
import { getCurrentPageFromPagination } from '@/domain/srp/getCurrentPageFromPaginationData'
import { getSrpComposedUrl } from '@/domain/srp/getSrpComposedUrl'
import { getSrpContentfulData } from '@/domain/srp/getSrpContentfulData'
import {
  type GetSrpPropsWithCache,
  type PageRedirect,
  getSrpLoadData,
} from '@/domain/srp/getSrpLoadData'
import { redirectLocationlessUrl } from '@/domain/srp/page-redirects/redirectLocationlessUrl'
import { useGetSearchResultsData } from '@/hooks/srp/useGetSearchResultsData'
import { useSearchLoadingState } from '@/hooks/srp/useSearchLoadingState'
import { useChameleon } from '@/hooks/useChameleon'
import { useExtendedSearch } from '@/hooks/useExtendedSearch'
import { useFavouriteListingUpdate } from '@/hooks/useFavouriteListingUpdate'
import { useLocale } from '@/hooks/useLocale'
import { useSaveSearchFromLogin } from '@/hooks/useSaveSearchFromLogin'
import { TopLeaderboardAdSlot } from '@/lib/ads/ad-slots/srp/TopLeaderboardAdSlot'
import { SearchAdsWrapper } from '@/lib/ads/ads-wrappers/SearchAdsWrapper'
import { AdSense } from '@/lib/ads/components/adsense'
import ClarivoyScript from '@/lib/ads/components/clarivoy/clarivoyScript'
import useClarivoy from '@/lib/ads/components/clarivoy/useClarivoy'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { useSrpTracking } from '@/lib/ga/hooks/useSrpTracking'
import profile from '@/lib/metrics/profileWrapper'
import { Flex } from '@/ui/atoms/flex'

const AppPenRealEstateModal = dynamic(
  () => import('@/components/srp/AppPenRealEstateModal').then((mod) => mod.AppPenRealEstateModal),
  { ssr: false }
)

const BaseLeaderboardAdSlot = dynamic(
  () =>
    import('@/lib/ads/ad-slots/srp/BaseLeaderboardAdSlot').then((mod) => mod.BaseLeaderboardAdSlot),
  { ssr: false }
)

const ContentfulContent = dynamic(
  () =>
    import('@/components/shared/contentful-content/ContentfulContent').then(
      (mod) => mod.ContentfulContent
    ),
  { ssr: false }
)

const mapStringToSearchOrigin = (origin: string): SearchOrigin | undefined =>
  Object.values(SearchOrigin).find((key) => key === origin)

export type SRPErrors = {
  hasServerError?: boolean
  hasResultsError?: boolean
}

export type SearchPageServerProps = {
  /**
   * Error states from SRP Anvil calls
   */
  errors?: SRPErrors
  /**
   * An ID that is used to identify the page load. This allows us to detect new
   * page loads where the url hasn't changed.
   */
  pageLoadId: string
  /**
   * The device used to browse Kijiji
   */
  userAgent?: string
}

const SearchPage = ({ pageLoadId, userAgent, errors: serverErrors }: SearchPageServerProps) => {
  const { setLoadingStates } = useSearchLoadingState()
  const [hasClientFetchAttemptError, setHasClientFetchAttemptError] = useState(false)
  // const [previousNetworkStatus, setPreviousNetworkStatus] = useState(NetworkStatus.loading)
  const { asPath, query, replace } = useRouter()
  const { routeLocale } = useLocale()

  const { data: userData } = useSession()
  const { updateFavourite } = useFavouriteListingUpdate()

  const srpUrl = getSrpComposedUrl(query)

  const isMobileForAds = isMobile(userAgent).phone

  const searchOrigin =
    typeof query?.origin === 'string' ? mapStringToSearchOrigin(query?.origin) : undefined

  const {
    data: srpData,
    loading: srpLoading,
    called: srpCalled,
    client,
    // networkStatus,
    loadingFilters,
  } = useGetSearchResultsData(
    {
      fetchPolicy: 'cache-first',
      onError: () => {
        setHasClientFetchAttemptError(true)
        setLoadingStates(false)

        // errored from network request, not the cache
        client.writeQuery({
          query: GetPendingSearchInputDocument,
          data: { srp: { pendingSearchInput: null } },
        })
      },
      skip: hasClientFetchAttemptError,
    },
    searchOrigin
  )

  // TODO: Re-attempt this loading state solution
  // useEffect(() => {
  //   const newResultsHasLoaded =
  //     (previousNetworkStatus === NetworkStatus.setVariables ||
  //       previousNetworkStatus === NetworkStatus.loading) &&
  //     networkStatus === NetworkStatus.ready

  //   if (loadingResults && newResultsHasLoaded) {
  //     console.log('network: FETCH COMPLETED!!!')
  //     setLoadingStates(false)
  //     setPreviousNetworkStatus(networkStatus)

  //     client.writeQuery({
  //       query: GetPendingSearchInputDocument,
  //       data: { srp: { pendingSearchInput: null } },
  //     })
  //   }
  // }, [previousNetworkStatus, networkStatus, loadingResults, setLoadingStates, client])

  const isInitialLoad = !srpData && srpLoading

  const { searchQuery, results, pagination } = srpData || {}

  const category = getSearchCategoryFromSearchQuery(searchQuery?.category)

  const currentPage = pagination ? getCurrentPageFromPagination(pagination) : 1
  const totalPageCount = pagination ? Math.ceil(pagination.totalCount / pagination.limit) : 1
  const listings = results?.organic || []

  const textJsonPromise = useMemo(() => {
    return getSrpContentfulData({ languageKey: routeLocale, url: srpUrl })
  }, [routeLocale, srpUrl])

  const errors = {
    hasServerError: !!serverErrors?.hasServerError,
    hasResultsError: srpCalled && !srpLoading && srpData?.results === null,
  }

  // Memoize the transformed location to prevent unnecessary re-renders for chameleon
  const memoizedLocation = useMemo(() => {
    return getUserLocationFromSearchQuery(searchQuery)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery?.location])

  useChameleon({
    categoryId: category.id,
    location: memoizedLocation,
    userAgent,
  })

  /**
   * UseEffect to be triggered if the user has signed in to favourite a listing
   * The decision is made based on a query parameter
   * Once the function has been triggered the query parameter should be removed from the URL
   */
  useEffect(() => {
    const listingId = query.listingId
    if (typeof listingId !== 'string' || !userData?.user) return
    /**
     * Remove listingId from the query params
     * If the initial value of listingId is not overwritten, the request will happen multiple times
     */
    delete query.listingId
    const { url } = qs.parseUrl(asPath)
    replace(qs.stringifyUrl({ url, query }))

    updateFavourite(false, {
      variables: { listingId },
      onCompleted: () => {
        trackEvent({
          action: GA_EVENT.WatchlistAdd,
          label: `adId=${listingId}`,
        })
      },
      onError: (error) => {
        // catch this error legitimate error
        if (error.message.includes('already favourited')) {
          return
        }
      },
    })
  }, [userData, asPath, updateFavourite, query, replace])

  useSaveSearchFromLogin({ searchString: srpData?.searchQuery.searchString })
  useSrpTracking()

  const { spacing } = useTheme()

  const { extendedListings } = useExtendedSearch()

  const { isScriptLoadable: isClarivoyScriptLoadable } = useClarivoy({
    listings,
    category,
    pageType: PAGE_TYPE.SEARCH_RESULTS_PAGE,
  })

  const renderSearchListings = () => {
    if (errors.hasResultsError) {
      return <SrpError />
    }

    if (category.id === CATEGORIES.TICKETS_CATEGORY_ID) {
      return <TicketListingCard />
    }

    return (
      <SearchList
        extendedRadiusListings={extendedListings}
        totalPageCount={totalPageCount}
        isMobile={isMobileForAds}
      />
    )
  }

  const hasRefetchUrlError = false

  return (
    <>
      {isClarivoyScriptLoadable && <ClarivoyScript defer />}

      {isBuyAndSellCategory(category.id) && (
        <ProductCarouselSeoJsonLd id="product-carousel-srp" listings={listings} />
      )}

      <SeoMetadata pageLoadId={pageLoadId}>
        {(pageH1) => (
          <SearchAdsWrapper>
            <BaseLayout
              showSearchBar={true}
              withAuthModal={true}
              includeRUMScripts={true}
              rumPageLabel="SRP"
            >
              <ErrorBoundary fingerprintId="SRP">
                {/* If there is server-side search or client-side refetch url error */}
                {hasClientFetchAttemptError || hasRefetchUrlError ? (
                  <SrpError />
                ) : (
                  <>
                    <PageContainer bottom={spacing.default} top={spacing.default}>
                      <SrpBreadcrumb />
                    </PageContainer>

                    <TopLeaderboardAdSlot isMobileForAds={isMobileForAds} />

                    <PageContainer bottom="6rem" top={spacing.default}>
                      <SrpHeader h1={pageH1} currentPage={currentPage} loading={isInitialLoad} />

                      <SrpGrid>
                        <FiltersSidebar
                          isMobileForAds={isMobileForAds}
                          isLoadingFilters={loadingFilters}
                        />

                        <Flex flexDirection="column" gap={spacing.default}>
                          <SrpResultsSort />

                          <RadiusBoostSystemMessage />

                          <AdSense id="AFSTop" />

                          {renderSearchListings()}

                          <BaseLeaderboardAdSlot />
                        </Flex>
                      </SrpGrid>

                      {textJsonPromise && (
                        <ErrorBoundary fingerprintId="ContentfulContent">
                          <ContentfulContent userAgent={userAgent} textJson={textJsonPromise} />
                        </ErrorBoundary>
                      )}

                      <AppPenRealEstateModal categoryId={category.id} />

                      <SearchFloatingButtons isMobileForAds={isMobileForAds} />
                    </PageContainer>
                  </>
                )}
              </ErrorBoundary>
            </BaseLayout>
          </SearchAdsWrapper>
        )}
      </SeoMetadata>
    </>
  )
}

const isRedirectUrl = (redirect: GetSrpPropsWithCache): redirect is PageRedirect =>
  !!(redirect as PageRedirect).redirect

export const getServerSideProps: GetServerSideProps = profile<GetServerSideProps>(
  'srp',
  'getServerSideProps',
  async (context) => {
    const gSSPstart = Date.now()
    const { query, req, res } = context

    const { apolloClient, forwardedHeaders, rawProps } = await initializePageServer(
      'srp',
      context,
      [TRANSLATION_KEYS.SRP, TRANSLATION_KEYS.LISTING, TRANSLATION_KEYS.FEEDBACK]
    )

    const url = getSrpComposedUrl(query)
    const userAgent = req.headers['user-agent']

    /**
     * When the URL doesn't contain the location information
     * then use the user preferences to execute the SRP/BRP search
     * AND url gets updated to match the location search
     */
    const locationlessRedirect = await profile(
      'srp',
      'redirectLocationlessUrl',
      redirectLocationlessUrl
    )({ url, cookies: req.cookies })

    if (locationlessRedirect) {
      return { redirect: locationlessRedirect.redirect }
    }

    const srpPropsOrRedirect = await profile(
      'srp',
      'getSrpLoadData',
      getSrpLoadData
    )({
      apolloClient,
      initProps: rawProps,
      requestHeaders: { headers: forwardedHeaders, cookies: req.cookies },
      url,
      userAgent,
    })

    if (isRedirectUrl(srpPropsOrRedirect)) {
      return { redirect: srpPropsOrRedirect.redirect }
    }

    debugServerTime('props', res, gSSPstart)

    return { props: { ...srpPropsOrRedirect, userAgent } }
  }
)

export default SearchPage
