import { z } from 'zod';
import { type ImageJsonLd } from 'next-seo';
import { JSON_LD_SCHEMA } from '../constants';

/**
 * Transforms an array of images to a schema.org ImageObject
 * Official docs: https://developers.google.com/search/docs/appearance/structured-data/image-license-metadata#structured-data
 *
 * @param images - Array of image objects containing a URL.
 * @returns Transformed array of images.
 * 
 * @example
 * const images = [
 *   { url: 'https://example.com/image1.jpg' },
 *   { url: 'https://example.com/image2.jpg' }
 * ];
 * const result = imageSeo(images);
 * 
 * result: [
 *   {
 *     "@context": "https://schema.org",
 *     "@type": "ImageObject",
 *     "contentUrl": "https://example.com/image1.jpg"
 *   }
 *   {
 *     "@context": "https://schema.org",
 *     "@type": "ImageObject",
 *     "contentUrl": "https://example.com/image2.jpg"
 *   }
 * ]
 */
export type ImagesListProps = Pick<ImageJsonLd, 'creditText' | 'contentUrl'>;

const imagesListPropsSchema = z.object({
  // We allow contentUrl to be optional but filter out images without a URL.
  contentUrl: z.string().url().optional(),
  creditText: z.string(),
});

export const imageSeo = (images: ImagesListProps[]) => {
  images.forEach(image => {
    imagesListPropsSchema.parse(image);
  });

  return images
    // Excluding images without a URL
    .filter(image => image.contentUrl)
    .map(image => ({
      "@context": JSON_LD_SCHEMA,
      "@type": "ImageObject",
      "contentUrl": image.contentUrl,
      "creditText": image.creditText
    }));
};
