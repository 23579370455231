import { type AttributeFilter } from '@kijiji/generated/graphql-types'
import SearchIcon from '@kijiji/icons/src/icons/Search'
import { useTranslation } from 'next-i18next'
import { type ChangeEvent, type FC, useState } from 'react'
import { useTheme } from 'styled-components'

import { getAttributeFilterValuesCount } from '@/domain/filters'
import { BodyText } from '@/ui/atoms/body-text'
import {
  type CheckboxesFieldProps,
  CheckboxesField,
} from '@/ui/molecules/checkboxes-field/CheckboxesField'
import { TextField } from '@/ui/molecules/text-field'

import { ScrollableCheckboxesContainer } from './styled'
import { MIN_SEARCH_BAR_VALUES } from './useFieldsVisibility'

type FilterCheckboxListProps = {
  filter: AttributeFilter
  options: CheckboxesFieldProps['checkboxes']
}

export const FilterCheckboxList: FC<FilterCheckboxListProps> = ({ filter, options }) => {
  const [searchText, setSearchText] = useState<string>('')

  const { colors } = useTheme()
  const { t } = useTranslation('srp')

  /**
   * Should only show search bar when the total number of options is
   * greater than or equal to the MIN defined
   */
  const shouldShowSearchBar = getAttributeFilterValuesCount(filter) >= MIN_SEARCH_BAR_VALUES

  const handleSearchFilterChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const inputTextValue = evt.target.value || ''
    setSearchText(inputTextValue.trim().toLowerCase())
  }

  const _options = searchText
    ? options?.filter((field) => !!field.label?.toString().toLowerCase().includes(searchText))
    : options

  return (
    <div data-testid="checkbox-filter">
      {shouldShowSearchBar && (
        <TextField
          aria-controls={`checkbox-${filter.label}-filter`}
          aria-hidden="true"
          data-testid="search-checkbox-filter"
          id={`checkbox-${filter.label}-checkbox-filter`}
          label={t('filters.search.label')}
          onChange={handleSearchFilterChange}
          startAdornment={<SearchIcon aria-hidden />}
          value={searchText}
        />
      )}
      <ScrollableCheckboxesContainer id={`checkbox-${filter.label}-filter`}>
        {!_options.length ? (
          <BodyText color={colors.grey.light1} size="medium">
            {t('filters.search.no_results')}
          </BodyText>
        ) : (
          <CheckboxesField
            id="checkbox-field"
            legend={filter.label || ''}
            checkboxes={_options}
            bottom="0"
          />
        )}
      </ScrollableCheckboxesContainer>
    </div>
  )
}
