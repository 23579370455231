import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgSquareFootage1: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.409 19.486H4.477v-1.95h.568c.27 0 .489-.227.489-.508a.498.498 0 00-.489-.506h-.568v-1.56H6.17c.27 0 .489-.227.489-.507a.498.498 0 00-.489-.507H4.477v-1.56h.568c.27 0 .489-.226.489-.506a.498.498 0 00-.489-.507h-.568v-1.56H6.17c.27 0 .489-.226.489-.506a.498.498 0 00-.489-.507H4.477v-1.56h.568c.27 0 .489-.226.489-.507a.498.498 0 00-.489-.507h-.568V4.514H8.41v14.972zM8.898 3.5h-4.91a.498.498 0 00-.488.507v15.986c0 .28.218.507.489.507h4.909c.27 0 .488-.227.488-.507V4.007a.498.498 0 00-.488-.507zM12.114 19.332v-1.456h1.556c.271 0 .489-.227.489-.507a.498.498 0 00-.489-.507h-1.556V15.63h.431c.27 0 .49-.227.49-.507a.498.498 0 00-.49-.507h-.431v-2.265l6.718 6.981h-6.719zm8.244.148L11.97 10.77a.479.479 0 00-.533-.11.508.508 0 00-.302.468v8.712c0 .28.22.507.489.507h8.387a.488.488 0 00.45-.313.522.522 0 00-.104-.553z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgSquareFootage1
