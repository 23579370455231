import CheckmarkSolidIcon from '@kijiji/icons/src/icons/CheckmarkSolid'
import { useTranslation } from 'next-i18next'
import { useTheme } from 'styled-components'

import { ShowAtOrLarger } from '@/components/shared/breakpoint'
import { MAX_ADDITIONAL_OPTIONS_DESKTOP, MAX_ADDITIONAL_OPTIONS_TABLET } from '@/constants/others'
import { BodyText } from '@/ui/atoms/body-text'

import { AdditionalOptionsContainer, AdditionalOptionsList } from './styled'

export type AdditionalOptionsProps = {
  isDealerUpdates?: boolean
  listingId: string
  options: string[]
}

export const AdditionalOptions = ({
  options,
  listingId,
  isDealerUpdates,
}: AdditionalOptionsProps) => {
  const { t } = useTranslation('srp')
  const { colors } = useTheme()

  if (!options.length) return null

  // Split the options array into two variables, one for the first 3 options and another for 4th, ignoring the rest
  const firstThreeOptions: string[] = []
  let fourthOption: string | undefined

  options.forEach((option, index) => {
    if (index < MAX_ADDITIONAL_OPTIONS_TABLET) {
      firstThreeOptions.push(option)
    } else if (index === MAX_ADDITIONAL_OPTIONS_DESKTOP - 1) {
      fourthOption = option
    }
  })

  return (
    <AdditionalOptionsContainer data-testid="additional-options">
      <BodyText
        color={colors.grey.primary}
        id={`${listingId}-options-title`}
        size="small"
        weight="medium"
      >
        {isDealerUpdates
          ? t('listing.additional_options.dealer_updates')
          : t('listing.additional_options.label')}
      </BodyText>

      <AdditionalOptionsList
        aria-labelledby={`${listingId}-options-title`}
        data-testid="additional-options-list"
      >
        {firstThreeOptions.map((item, index) => (
          <BodyText
            as="li"
            color={colors.grey.primary}
            key={`${listingId}-additional-option-${index}`}
            size="small"
          >
            <CheckmarkSolidIcon aria-hidden /> {item}
          </BodyText>
        ))}
        {!!fourthOption && (
          <ShowAtOrLarger breakpoint="large">
            <BodyText
              as="li"
              color={colors.grey.primary}
              key={`${listingId}-additional-option-4`}
              size="small"
            >
              <CheckmarkSolidIcon aria-hidden /> {fourthOption}
            </BodyText>
          </ShowAtOrLarger>
        )}
      </AdditionalOptionsList>
    </AdditionalOptionsContainer>
  )
}
