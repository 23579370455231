import { JSON_LD_SCHEMA_URLS } from '@kijiji/seo/constants'
import { getAutosWikipediaLink } from '@kijiji/seo/constants/autosWikis'
import { type TFunction } from 'next-i18next'

import {
  AUTOS_ATTRIBUTES,
  getTransmissionTypeLiteralKey,
  MOTORCYCLES_ATTRIBUTES,
} from '@/components/srp/search-list/domain'

export type AutosSeoAttributes = {
  brandName: string
  bodyType: string
  color: string
  driveWheelConfiguration: string | null
  fuelType: string
  itemCondition: string
  kilometers: string
  model: string
  numberOfDoors: string
  transmission: string
  vehicleConfiguration: string
  vehicleModelDate: string
  vehicleSeatingCapacity: string
  vehicleIdentificationNumber: string
  hasVin: boolean
}

export const getDriveWheelConfiguration = (driveWheelConfiguration: string) => {
  switch (driveWheelConfiguration) {
    case 'awd':
      return JSON_LD_SCHEMA_URLS.AUTOS.ALL_WHEEL
    case '4x4':
      return JSON_LD_SCHEMA_URLS.AUTOS.FOUR_WHEEL
    case 'fwd':
      return JSON_LD_SCHEMA_URLS.AUTOS.FRONT_WHEEL
    case 'rwd':
      return JSON_LD_SCHEMA_URLS.AUTOS.REAR_WHEEL
    default:
      return null
  }
}

/**
 * properties for Cars & Trucks are almost identical to Motorcycles
 */
export const getAutosSeoAttributes = (
  attributes: Record<string, string[]>,
  t: TFunction
): AutosSeoAttributes => {
  const brandName =
    attributes[AUTOS_ATTRIBUTES.BRAND_NAME]?.[0] ||
    attributes[MOTORCYCLES_ATTRIBUTES.BRAND_NAME]?.[0]
  const bodyType = attributes[AUTOS_ATTRIBUTES.BODY_TYPE]?.[0]
  const color = attributes[AUTOS_ATTRIBUTES.COLOR]?.[0]

  const driveWheelConfiguration = getDriveWheelConfiguration(
    attributes[AUTOS_ATTRIBUTES.DRIVE_TRAIN]?.[0] ||
      attributes[MOTORCYCLES_ATTRIBUTES.DRIVE_TRAIN]?.[0]
  )

  const fuelType =
    attributes[AUTOS_ATTRIBUTES.FUEL_TYPE]?.[0] || attributes[MOTORCYCLES_ATTRIBUTES.FUEL_TYPE]?.[0]

  const itemCondition =
    attributes[AUTOS_ATTRIBUTES.VEHICLE_TYPE]?.[0] === 'New'
      ? JSON_LD_SCHEMA_URLS.NEW_CONDITION
      : JSON_LD_SCHEMA_URLS.USED_CONDITION

  const kilometers = attributes[AUTOS_ATTRIBUTES.CAR_MILEAGE]?.[0]

  const model =
    attributes[AUTOS_ATTRIBUTES.MODEL]?.[0] || attributes[MOTORCYCLES_ATTRIBUTES.MODEL]?.[0]

  const numberOfDoors = attributes[AUTOS_ATTRIBUTES.NUM_OF_DOORS]?.[0]

  const transmission = t(
    getTransmissionTypeLiteralKey(
      parseInt(
        attributes[AUTOS_ATTRIBUTES.TRANSMISSION]?.[0] ||
          attributes[MOTORCYCLES_ATTRIBUTES.TRANSMISSION]?.[0]
      )
    )
  )

  const vehicleConfiguration =
    attributes[AUTOS_ATTRIBUTES.TRIM]?.[0] || attributes[MOTORCYCLES_ATTRIBUTES.TRIM]?.[0]

  const vehicleModelDate = attributes[AUTOS_ATTRIBUTES.YEAR]?.[0]
  const vehicleSeatingCapacity = attributes[AUTOS_ATTRIBUTES.NUM_OF_SEATS]?.[0]
  const vehicleIdentificationNumber = attributes[AUTOS_ATTRIBUTES.VIN]?.[0]
  const hasVin = !!vehicleIdentificationNumber

  return {
    brandName,
    bodyType,
    color,
    driveWheelConfiguration,
    fuelType,
    itemCondition,
    kilometers,
    model,
    numberOfDoors,
    transmission,
    vehicleConfiguration,
    vehicleModelDate,
    vehicleSeatingCapacity,
    vehicleIdentificationNumber,
    hasVin,
  }
}

/**
 * @param attributes from Anvil, varying by category and specific listing
 * @returns structured markup required for vehicle & car schema types
 */
export const getAutosStructuredMarkup = (attributes: AutosSeoAttributes) => {
  const {
    brandName,
    bodyType,
    color,
    driveWheelConfiguration,
    fuelType,
    itemCondition,
    kilometers,
    model,
    numberOfDoors,
    transmission,
    vehicleConfiguration,
    vehicleModelDate,
    vehicleSeatingCapacity,
    vehicleIdentificationNumber,
  } = attributes

  return {
    /**
     * properties for Cars & Trucks are almost identical to Motorcycles
     * https://developers.google.com/search/docs/appearance/structured-data/vehicle-listing
     * https://schema.org/Vehicle
     * https://schema.org/Motorcycle
     * https://schema.org/Car
     */

    /* CAR REQUIRED PROPERTIES */
    brand: {
      '@type': 'Brand',
      name: brandName,
    },
    itemCondition,
    mileageFromOdometer: {
      '@type': 'QuantitativeValue',
      value: kilometers,
      unitCode: 'KMT',
    },
    model,
    // not all car/motorcycle/vehicle listings have a VIN
    vehicleIdentificationNumber: vehicleIdentificationNumber ?? null,
    vehicleModelDate,

    /* CAR RECOMMENDED PROPERTIES */
    bodyType,
    color,
    driveWheelConfiguration,
    numberOfDoors,
    vehicleConfiguration, // vehicle trim
    vehicleEngine: {
      '@type': 'EngineSpecification',
      fuelType,
    },
    vehicleSeatingCapacity,
    vehicleTransmission: transmission,
    sameAs: getAutosWikipediaLink(brandName, model),
  }
}
