import { isAnyCarsVehiclesCategory } from '@kijiji/category'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'

import { PROMOTIONAL_LISTING_AD_SOURCE } from '@/constants/search'
import { Badge } from '@/ui/atoms/badge'
import { BadgeSize, BadgeVariant } from '@/ui/atoms/badge/Badge'
import { Flex } from '@/ui/atoms/flex'

import { ProvincialTopAdBadge } from './ProvincialTopAdBadge'

export type ProvincialLabelProps = {
  /**
   * Defines if listing is a top ad
   */
  isTopAd?: boolean
  /**
   * ad source
   */
  label: string
  /**
   * category id of the listing
   */
  categoryId: number
  /**
   * defines if the label should be displayed as a single label
   * (e.g. when the listing is a provincial top ad and a top ad)
   */
  showAsSingleLabel?: boolean
}

export const ProvincialLabel: FC<ProvincialLabelProps> = ({
  label,
  categoryId,
  isTopAd,
  showAsSingleLabel,
}) => {
  const { t } = useTranslation('srp')

  const isProvincial =
    label === PROMOTIONAL_LISTING_AD_SOURCE.PROV_TOP_AD && isAnyCarsVehiclesCategory(categoryId)

  if (!isTopAd) {
    return null
  }

  const getBadges = () => {
    if (showAsSingleLabel) {
      return isProvincial ? (
        <Badge
          size={BadgeSize.SMALL}
          variant={BadgeVariant.LIGHT3}
          label={t('listing.provincial_top_ad.badge').toUpperCase()}
        />
      ) : (
        <Badge
          size={BadgeSize.SMALL}
          variant={BadgeVariant.LIGHT3}
          label={t('listing.top_ad.badge').toUpperCase()}
        />
      )
    }

    return (
      <>
        {isProvincial ? <ProvincialTopAdBadge /> : null}
        <Badge label={t(`listing.top_ad.badge`)} />
      </>
    )
  }

  /**
   * - When SRP encounters a Cars & Vehicles search on All Categories it should show only the Top Ad Badge
   * - When User is in A Cars & Vehicles search from a Cars & Vehicles category it should display both provincial & top ad badges
   *
   * If the ad is a provincial top ad, it will always be a topAd as well
   */
  return isTopAd ? <Flex gap="0.4rem">{getBadges()}</Flex> : null
}
