import FavouriteOutlineThick from '@kijiji/icons/src/icons/FavouriteOutlineThick'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'

import { AssistChip } from '@/components/shared/assist-chip'
import { type GaSaveSearchType } from '@/hooks/useSaveSearchActions'
import { useScrollOutsideMainTag } from '@/hooks/useScrollOutsideMainTag'
import { GA_SAVE_SEARCH_TYPES } from '@/lib/ga/constants/ga'

import { SaveSearchFloatingContainer } from './styled'

type SaveSearchFloatingButtonProps = {
  handleActivateSaveSearch: (type: GaSaveSearchType) => Promise<void>
}

export const SaveSearchFloatingButton: FC<SaveSearchFloatingButtonProps> = ({
  handleActivateSaveSearch,
}) => {
  const { t } = useTranslation('srp')
  const { isOutsideMain } = useScrollOutsideMainTag()

  const handleClick = () => handleActivateSaveSearch(GA_SAVE_SEARCH_TYPES.floatingActionButton)

  return (
    <SaveSearchFloatingContainer isVisible={!isOutsideMain}>
      <AssistChip
        label={t('save_search.buttons.save_this_search')}
        onClick={handleClick}
        data-testid="save-search-button"
      >
        <FavouriteOutlineThick aria-hidden />
      </AssistChip>
    </SaveSearchFloatingContainer>
  )
}
