import Script from 'next/script'
import { type ComponentProps } from 'react'

import { useScriptOptOut } from '@/hooks/useScriptOptout'

import { clarivoyEvents } from './clarivoyConfig'

declare global {
  interface Window {
    Clarivoy: {
      add3PARecord: (dealerIds: string, pageType: string) => void
    } | null
  }
}

export const sendClarivoyData = ({
  dealerIds,
  pageType,
}: {
  dealerIds: string
  pageType: string
}) => {
  window.Clarivoy?.add3PARecord(dealerIds, pageType)
}

export const isClarivoyLoaded = () => !!window.Clarivoy && !!window.Clarivoy.add3PARecord

export const transformDealerIdsToClarivoyFormat = (dealerIds: (number | undefined)[]) =>
  dealerIds.join(', ')

export const onClarivoyLoaded = (cb: () => void) => {
  window.addEventListener(
    clarivoyEvents.ClarivoyLoaded,
    function () {
      cb()
    },
    false
  )
}

const ClarivoyScript = (props: Omit<ComponentProps<typeof Script>, 'src'>) => {
  const { shouldRenderScript } = useScriptOptOut()

  if (!shouldRenderScript('clarivoy')) {
    return null
  }
  return <Script {...props} src="//tags-cdn.clarivoy.com/kijiji/td/tva/loader.js" />
}

export default ClarivoyScript
