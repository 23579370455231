import { Flex } from '@/ui/atoms/flex'
import { Skeleton } from '@/ui/atoms/skeleton'

import { LoadingListingCardsContainer, LoadingListingContent, RectangleSkeleton } from './styled'

export const LoadingListingCards = () => {
  const lineHeight = '2rem'

  return (
    <LoadingListingCardsContainer data-testid="loading-listing-card">
      <Flex gap="1.6rem">
        <RectangleSkeleton variant="rectangle" size="10.4rem" />
        <LoadingListingContent flexDirection="column" gap=".8rem">
          <Skeleton variant="inline" width="36.4%" height={lineHeight} />
          <Skeleton variant="inline" width="100%" height={lineHeight} />
          <Skeleton variant="inline" width="70.5%" height={lineHeight} />
          <Skeleton variant="inline" width="100%" height={lineHeight} count={2} />
        </LoadingListingContent>
      </Flex>
    </LoadingListingCardsContainer>
  )
}
