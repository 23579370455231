import { type AttributeFilter } from '@kijiji/generated/graphql-types'

import { getDescriptionFromSelectedValues } from './getDescriptionFromSelectedValues'

/**
 * Returns the label for each of the filters based on the selected options
 *
 * @param filter
 * @returns
 */
export const getAttributeFilterDescription = (filter: AttributeFilter): string => {
  const selectedValues = filter.selectedValues || []

  if (!selectedValues.length) return ''

  const description = getDescriptionFromSelectedValues(selectedValues, filter.values)
  const selectedValuesCount = selectedValues.length

  return selectedValuesCount == 1 ? description : `${description} +${selectedValuesCount - 1}`
}
