import { type ReactElement } from 'react'
import { useTheme } from 'styled-components'

import { AttributeListItemFlex, AttributeListItemText } from './styled'

type AutosAttributeListItemProps = {
  icon: ReactElement
  text: string
}

/**
 * `AutosAttributeListItem` is a component that displays an icon and text as a list item.
 * It is intended to be used in the `AutosAttributeList` component.
 *
 * @component
 * @param {ReactElement} props.icon - The icon to be displayed in the list item.
 * @param {string} props.text - The text to be displayed in the list item.
 * @returns {ReactElement} Returns an `AutosAttributeListItem` React element.
 */
export const AutosAttributeListItem = ({ icon, text, ...rest }: AutosAttributeListItemProps) => {
  const theme = useTheme()

  return (
    <AttributeListItemFlex gap="0.2rem" alignItems="center" {...rest}>
      {icon}
      <AttributeListItemText color={theme.colors.grey.primary}>{text}</AttributeListItemText>
    </AttributeListItemFlex>
  )
}
