import { extractCategoryIdsFromPath } from '@/domain/category/extractCategoryIdsFromPath'
import { getCurrentPageFromPagination } from '@/domain/srp/getCurrentPageFromPaginationData'
import { useGetSearchCategory } from '@/hooks/category/useGetSearchCategory'
import { useGetLocation } from '@/hooks/location/useGetLocation'
import { useGetSearchResultsData } from '@/hooks/srp/useGetSearchResultsData'
import { useLocale } from '@/hooks/useLocale'
import { createSrpGptConfig } from '@/lib/ads/ads-config/srp/createSrpGptConfig'
import { useAdSense } from '@/lib/ads/hooks/useAdSense'
import { getAdSenseConfig } from '@/lib/ads/utils/adsense/getAdSenseConfig'
import { cleanCategoryPathForDisplayAds } from '@/lib/ads/utils/cleanCategoryPathForDisplayAds'

import { useSrpGpt } from './useSrpGpt'

/**
 * Custom hook to handle the logic for SRP advertisements
 * returns configurations and targeting values to be passed to AdvertisingProvider
 *
 * @returns gpt - targeting values
 * @returns srpGptConfig - GPT configuration object
 */
export const useSrpAds = () => {
  const { data } = useGetSearchResultsData()
  const { routeLocale } = useLocale()
  const { location } = useGetLocation()
  const { category } = useGetSearchCategory()

  const { searchQuery, pagination, controls } = data || {}

  const currentPage = getCurrentPageFromPagination(pagination || {})
  const cleanedPath = cleanCategoryPathForDisplayAds(category.path || [])
  const allCategoryIds = extractCategoryIdsFromPath(category.path || [])

  const gpt = useSrpGpt({
    categoryIds: allCategoryIds,
    currentPage: currentPage,
    keywords: searchQuery?.keywords || '',
    locationId: location.id,
    routeLocale,
    filters: controls?.filtering || [],
    categories: cleanedPath,
    totalListings: pagination?.totalCount || 0,
  })

  const srpGptConfig = createSrpGptConfig(gpt)

  const adSenseConfig = getAdSenseConfig({
    allCategoryIds: allCategoryIds,
    location,
    currentPage,
    keywords: searchQuery?.keywords || '',
    routeLocale,
    categoryPath: category.path || [],
  })

  useAdSense({
    ...adSenseConfig,
    active: true,
    containers: [
      { container: 'AFSTop', width: '100%', number: 2 },
      { container: 'AFSBottom', width: '100%', number: 2 },
    ],
  })

  return {
    srpGptConfig,
    gpt,
  }
}
