import { useTranslation } from 'next-i18next'
import { useTheme } from 'styled-components'

import { ShowAtOrLarger } from '@/components/shared/breakpoint'
import { type IntlResponse, isIntlResponse } from '@/types/translations'
import { Divider } from '@/ui/atoms/divider'
import { Flex } from '@/ui/atoms/flex'
import { Spacing } from '@/ui/atoms/spacing'

import { SrpResults } from '../results-sort/results'
import { SaveSearchToggle } from '../save-search'
import { LoadingSaveSearchToggle } from '../srp-loading/LoadingSaveSearchToggle'
import { LoadingSrpHeader } from '../srp-loading/LoadingSrpHeader'
import { SrpHeaderContainer, SrpHeaderSearchTitle } from './styled'

/**
 * Top of the search page
 */

export type SrpHeaderProps = {
  h1: string | IntlResponse
  currentPage: number
  loading: boolean
}

export const SrpHeader = ({ h1, currentPage, loading = true }: SrpHeaderProps) => {
  const { t } = useTranslation('srp')
  const { colors, spacing } = useTheme()

  const shouldDisplayPageNumber = currentPage > 1

  const h1Text = isIntlResponse(h1) ? t(h1.intl, { ...h1.params }) : h1

  return (
    <>
      {loading ? (
        <LoadingSrpHeader />
      ) : (
        <SrpHeaderContainer>
          <>
            <SrpHeaderSearchTitle
              aria-live="polite"
              color={colors.grey.primary}
              data-testid="srp-header-title"
              size="medium"
            >
              {h1Text}
              {shouldDisplayPageNumber &&
                t('header.results.title.page_number', { pageNumber: currentPage })}
            </SrpHeaderSearchTitle>

            <Flex alignItems="center" justifyContent="space-between" gap={spacing.defaultSmall}>
              <SrpResults showOnLargeBreakpoint={false} />
            </Flex>
          </>
        </SrpHeaderContainer>
      )}

      <ShowAtOrLarger breakpoint="medium">
        <Divider bottom={spacing.default} top={spacing.default} />
      </ShowAtOrLarger>

      <Spacing mBottom="0" mTop={spacing.default} large={{ mTop: '0', mBottom: spacing.default }}>
        {loading ? (
          <LoadingSaveSearchToggle />
        ) : (
          <Flex>
            <SaveSearchToggle />
          </Flex>
        )}
      </Spacing>
    </>
  )
}
