import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { useGetSearchResultsData } from '@/hooks/srp/useGetSearchResultsData'
import { useSaveSearchActions } from '@/hooks/useSaveSearchActions'
import { useScrollOutsideMainTag } from '@/hooks/useScrollOutsideMainTag'
import { GPT_COMPONENT_ID } from '@/lib/ads/constants/adSlots'
import { Flex } from '@/ui/atoms/flex'

import { FilterMobileChip } from '../filters/FilterMobileChip'
import { SaveSearchGuestModal } from '../save-search'
import { SavedSearchActionFeedback } from '../save-search/SavedSearchActionFeedback'
import { SaveSearchFloatingButton } from '../save-search/SaveSearchFloatingButton'
import { MobileLeaderboard, SearchButtonsWrapper, SearchFloatingContainer } from './styled'

type SearchFloatingButtons = {
  isMobileForAds?: boolean
}

export const SearchFloatingButtons: FC<SearchFloatingButtons> = ({ isMobileForAds }) => {
  const { spacing } = useTheme()
  const { data: { searchQuery } = {} } = useGetSearchResultsData()
  const { isOutsideMain } = useScrollOutsideMainTag()

  const keywords = searchQuery?.keywords || ''
  const searchString = searchQuery?.searchString || ''
  const appliedFiltersCount = searchQuery?.filters.length || 0

  const {
    activateError,
    deactivateError,
    handleActivateSaveSearch,
    handleCloseModal,
    isModalOpen: isSaveSearchModalOpen,
    isSearchSaved,
    lookupLoading,
    showUserFeedback,
    signInRedirectUrl,
  } = useSaveSearchActions({ searchString })

  return (
    <>
      <SaveSearchGuestModal
        error={!!activateError || !!deactivateError}
        handleCloseModal={handleCloseModal}
        isModalOpen={isSaveSearchModalOpen}
        keywords={keywords}
        signInRedirectUrl={signInRedirectUrl}
      />

      <SearchFloatingContainer>
        {
          <SearchButtonsWrapper
            flexDirection="column"
            gap={spacing.defaultSmall}
            isVisible={!isOutsideMain}
            alignItems="center"
            justifyContent="center"
          >
            {/* to prevent CLS, we nned to wait for the lookup so we know whether to display 1 or 2 buttons */}
            {!lookupLoading && (
              <Flex gap={spacing.default}>
                <FilterMobileChip appliedFiltersCount={appliedFiltersCount} />

                {!isSearchSaved && (
                  <SaveSearchFloatingButton handleActivateSaveSearch={handleActivateSaveSearch} />
                )}
              </Flex>
            )}
          </SearchButtonsWrapper>
        }

        {isMobileForAds && (
          <MobileLeaderboard
            id={GPT_COMPONENT_ID.MWEB_LEADERBOARD}
            data-testid={GPT_COMPONENT_ID.MWEB_LEADERBOARD}
          />
        )}
      </SearchFloatingContainer>

      {showUserFeedback && <SavedSearchActionFeedback type={showUserFeedback} />}
    </>
  )
}
