import styled from 'styled-components'

import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const SrpSortContainer = styled.div(
  ({ theme }) => `
  height: 4.8rem;
  min-width: 100%;
  
  ${MEDIA_QUERIES(theme).medium}{
    min-width: 25.6rem;
  }
`
)

export const SrpResultsSortContainer = styled.div(
  ({ theme }) => `
  ${MEDIA_QUERIES(theme).large} {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
`
)
