import {
  type UserLocation,
  useGetLocationPathFromIdLazyQuery,
} from '@kijiji/generated/graphql-types'
import { useDecision } from '@optimizely/react-sdk'
import isMobile from 'ismobilejs'
import { useSession } from 'next-auth/react'
import { useEffect, useRef } from 'react'

import { ALL_CATEGORIES_ID_NUM } from '@/constants/category'
import { ACCEPTED_L1_LOCATIONS, CHAMELEON_FAST_URL } from '@/constants/chameleon'
import {
  ALL_CANADA_LABEL_EN,
  ALL_CANADA_LOCATION_ID,
  DEFAULT_USER_LOCATION,
} from '@/constants/location'
import { isUserAuthenticated } from '@/domain/user'
import { FEATURE_FLAG } from '@/lib/optimizely'

export type UseChameleonProps = {
  categoryId?: number
  location?: UserLocation
  userAgent?: string
}

export const useChameleon = ({
  categoryId = ALL_CATEGORIES_ID_NUM,
  location = DEFAULT_USER_LOCATION,
  userAgent = '',
}: UseChameleonProps) => {
  // Create a ref so that we only run the useEffect once
  const hasSentDataToChameleon = useRef(false)

  // We only use Chameleon if the toggle is enabled, and the user is logged in
  const [chameleonToggle] = useDecision(FEATURE_FLAG.CHAMELEON_TRACKING)
  const { data: userData, status: userStatus } = useSession()
  const [getLocationPath] = useGetLocationPathFromIdLazyQuery()
  const isMobilePhone = isMobile(userAgent).phone

  const getMobileOperatingSystem = () => {
    if (isMobile(userAgent).apple.device) {
      return 'iOS'
    } else if (isMobile(userAgent).android.device) {
      return 'Android'
    } else {
      return ''
    }
  }

  const sendDataToChameleon = async () => {
    if (chameleonToggle.enabled) {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const chmln = require('@chamaeleonidae/chmln')
      chmln.init(process.env.NEXT_PUBLIC_CHAMELEON_TOKEN, {
        fastUrl: CHAMELEON_FAST_URL,
      })

      const { data: locationData } = await getLocationPath({
        variables: {
          locationId: location.id || ALL_CANADA_LOCATION_ID,
        },
      })

      const locationList = locationData?.location?.locationPaths || []

      // L1 will be at index 1, which is undefined if user is searching all of Canada
      const isUserInSupportedLocation =
        locationList.length >= 2 && ACCEPTED_L1_LOCATIONS.includes(locationList[1].id)

      if (isUserAuthenticated(userStatus) && isUserInSupportedLocation) {
        hasSentDataToChameleon.current = true
        // Send data to Chameleon
        chmln.identify(userData?.user.sub, {
          category_id: categoryId,
          device_type: isMobilePhone ? 'Mobile' : 'Desktop',
          email: userData?.user.email,
          l1_location: locationList[1]?.id,
          l2_location: locationList[2]?.id,
          l3_location: locationList[3]?.id,
          location_name: location?.name?.en_CA || ALL_CANADA_LABEL_EN,
          logged_in_status: true,
          mobile_os: getMobileOperatingSystem(),
        })
      }
    }
  }

  useEffect(() => {
    if (hasSentDataToChameleon.current) {
      return
    } else if (isUserAuthenticated(userStatus)) {
      sendDataToChameleon()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, userStatus, location])
}
