import {
  type AppliedDateFilter,
  type DateFilter as DateFilterType,
} from '@kijiji/generated/graphql-types'
import { useTranslation } from 'next-i18next'
import { type ChangeEvent, type FC, useState } from 'react'

import { CURRENT_DATE } from '@/constants/others'
import { FILTER_CANONICAL } from '@/constants/search'
import { useFiltersLoading } from '@/hooks/filters/useFiltersLoading'
import { AccordionItem } from '@/ui/atoms/accordion'
import { DateField } from '@/ui/molecules/date-field'

import { type FilterProps } from '../FiltersAccordion/FiltersAccordion'
import { ApplyFilter } from './ApplyFilter'
import { SelectedValue } from './SelectedValue'

export const DateFilter: FC<FilterProps<DateFilterType, AppliedDateFilter>> = ({
  filter,
  parentFilter,
  isSrpLoading = false,
  refetch,
}) => {
  const { t } = useTranslation('srp')
  const [loading, setLoading] = useFiltersLoading(isSrpLoading)

  const { name, label, selectedValue } = filter

  const isDateOfBirthFilter = name === FILTER_CANONICAL.BIRTH_DATE

  const [value, setValue] = useState<string>(selectedValue || '')

  const parentFilterLabel =
    parentFilter &&
    !parentFilter.isSelected &&
    t('filters.select_parent_filter', { parentFilter: parentFilter?.label })

  const filterDescription =
    parentFilterLabel || (!!filter?.selectedValue && <SelectedValue filter={filter} />)

  const handleDateChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const date = evt.target.value
    setValue(date)
  }

  const handleReset = () => {
    setValue('')
    setLoading(true)

    const trackingLabel = `${name} = [undefined]`
    refetch({ filterName: name, value: null }, trackingLabel)
  }

  const handleApply = () => {
    setLoading(true)

    const trackingLabel = `${name} = [${value}]`
    refetch({ filterName: name, value }, trackingLabel)
  }

  return (
    <AccordionItem
      id={name}
      title={label || ''}
      data-testid="integer-range-filter"
      description={filterDescription}
      isDisabled={parentFilter && !parentFilter.isSelected}
    >
      <DateField
        id={name}
        key={name}
        label={`${label}`}
        onChange={handleDateChange}
        value={value}
        max={isDateOfBirthFilter ? CURRENT_DATE : undefined}
      />

      <ApplyFilter
        filterLabel={label || ''}
        handleApplyFilter={handleApply}
        handleResetFilter={handleReset}
        shouldShowResetButton={value.length > 0}
        isLoading={loading}
        isDisabled={isSrpLoading}
      />
    </AccordionItem>
  )
}
