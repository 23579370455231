import { useTranslation } from 'next-i18next'
import { type FC } from 'react'

import { ShowMoreButton } from './styled'

export type ViewAllFiltersButtonProps = {
  /**
   * Defines if toggle should be rendered
   */
  displayToggle: boolean
  /**
   * Defines the action when clicking the toggle
   */
  handleClick: () => void
  /**
   * Defines the state of the toggle - if is already "showing more" or "showing less"
   */
  showMore: boolean
}

export const ViewAllFiltersButton: FC<ViewAllFiltersButtonProps> = ({
  displayToggle,
  showMore,
  handleClick,
}) => {
  const { t } = useTranslation('srp')

  if (!displayToggle) return null

  return (
    <ShowMoreButton onClick={handleClick} variant="tertiary">
      {showMore ? t('filters.show.less') : t('filters.show.more')}
    </ShowMoreButton>
  )
}
