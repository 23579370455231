import { type PAGE_TYPE } from '@/constants/pageTypes'

import { determineCategoryId } from './determineCategoryId'
import { determinePageNumber } from './determinePageNumber'
import { determineGeoId } from './locationIdToGeoIdMapping'
import { determinePageType } from './pageTypeMapping'

export type CreateAdsenseChannelParams = {
  expInd?: '1' | '0' // 1 being used for NWA, 0 for legacy
  os?: 'w' | 'm' // web vs mweb
  pageType: PAGE_TYPE
  l1Catid?: string
  l2Catid?: string
  ads?: string
  page?: number // SRP page number
  locationId: number
  expName?: string
  expGroup?: string
}

/**
 * Creates an AdSense channel based on the provided parameters.
 *
 * @param {CreateAdsenseChannelParams} params - The parameters for creating the AdSense channel.
 * @returns {string} The generated AdSense channel.
 *
 * @example
 * createAdsenseChannel({
 *   pageType: PAGE_TYPE.VIEW_AD,
 *   l1Catid: 27,
 *   l2Catid: 174,
 *   locationId: 91,
 *   expName: 'kcan',
 *   expGroup: 'a',
 * }); // Returns '0_w_vip_27_174_x_1_91_x_kcan_a'
 */
export function createAdsenseChannel({
  expInd = '0',
  os = 'w',
  pageType,
  l1Catid,
  l2Catid,
  ads = 'x',
  page = 1,
  locationId,
  expName = 'x',
  expGroup,
}: CreateAdsenseChannelParams): string {
  const pageTypeId = determinePageType(pageType)

  const categoryId = determineCategoryId(l1Catid, l2Catid)
  const geoId = determineGeoId(locationId)
  const pageBin = determinePageNumber(page, pageType)

  const channelComponents =
    expInd === '0'
      ? [expInd, os, pageTypeId, categoryId, ads, pageBin, geoId, expName, expGroup]
      : [expInd, os, 'x', 'x_x', 'x', 'x', 'x', expName, expGroup]

  const channel = channelComponents.filter(Boolean).join('_').toLowerCase()

  return channel
}
