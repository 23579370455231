import ConditionCertifiedIcon from '@kijiji/icons/src/icons/ConditionCertifiedPreOwned'
import { useTranslation } from 'next-i18next'
import { useTheme } from 'styled-components'

import { type BadgeSize } from '@/ui/atoms/badge'

import { AutosBadgeBase } from './AutosBadgeBase'
import { ListingOptions, OptionCustomIcon } from './styled'

type AutosBadgeCarFaxProps = {
  variant?: 'text' | 'badge'
  size?: BadgeSize
}

export const AutosBadgeCertifiedPreOwner = ({ variant, size }: AutosBadgeCarFaxProps) => {
  const { t } = useTranslation('srp')
  const { colors } = useTheme()

  if (variant === 'badge') {
    return (
      <AutosBadgeBase
        size={size}
        label={t('listing.autos_badge.cpo')}
        leftIcon={<ConditionCertifiedIcon color={colors.purple.primary} size="xxsm" />}
      />
    )
  }

  return (
    <ListingOptions color={colors.grey.primary} data-testid="autos-badge-cpo" size="small">
      <OptionCustomIcon color={colors.blue.primary} aria-hidden />
      {t('listing.autos_badge.cpo')}
    </ListingOptions>
  )
}
