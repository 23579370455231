import { type SearchCategoryPath, type UserLocation } from '@kijiji/generated/graphql-types'

import { adSenseConfiguration } from '@/lib/ads/components/adsense/adsenseConfig'

import { generateAdSenseChannel } from './generateAdSenseChannel'

type GetAdSenseConfigParams = {
  allCategoryIds: string[]
  location?: UserLocation
  currentPage: number
  keywords: string
  routeLocale: string
  categoryPath: SearchCategoryPath[]
}

export const getAdSenseConfig = ({
  allCategoryIds,
  location,
  currentPage,
  keywords,
  routeLocale,
  categoryPath,
}: GetAdSenseConfigParams) => {
  // We concatenate two channels, with the second one having `kcan19539_a` passed as the "test experiment group"
  // Ex: (e.g. 0_w_brl_10_o_x_1_o_x+1_w_brl_10_o_x_1_kcan999_a)

  const adSenseChannels = [
    generateAdSenseChannel({
      allCategoryIds,
      locationId: location?.id,
      currentPage,
      keywords,
      overrides: {
        expInd: '0',
      },
    }),
  ]
    .filter(Boolean)
    .join('+')

  return adSenseConfiguration({
    active: true,
    containers: [
      {
        container: 'AFSTop',
        width: '100%',
        number: 2,
      },
      {
        container: 'AFSBottom',
        width: '100%',
        number: 2,
      },
    ],
    hl: routeLocale,
    keywords,
    location,
    currentPage,
    categoryPath,
    channel: adSenseChannels,
  })
}
