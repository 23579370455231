import { useTranslation } from 'next-i18next'
import { useTheme } from 'styled-components'

import { type BadgeSize } from '@/ui/atoms/badge'

import { AutosBadgeBase } from './AutosBadgeBase'
import { ListingOptions, OptionCustomIcon } from './styled'

type AutosBadgeCarFaxProps = {
  variant?: 'text' | 'badge'
  size?: BadgeSize
}

export const AutosBadgeFinancing = ({ variant, size }: AutosBadgeCarFaxProps) => {
  const { t } = useTranslation('srp')
  const { colors } = useTheme()

  if (variant === 'badge') {
    return <AutosBadgeBase size={size} label={t('listing.autos_badge.financing')} />
  }

  return (
    <ListingOptions color={colors.grey.primary} size="small">
      <OptionCustomIcon
        color={colors.green.primary}
        data-testid="autos-badge-financing"
        aria-hidden
      />
      {t('listing.autos_badge.financing')}
    </ListingOptions>
  )
}
