import { ShowAtOrLarger, ShowWhenSmaller } from '@/components/shared/breakpoint'

import { LoadingBlockContainer } from './LoadingBlockContainer'

export const LoadingSrpHeader = () => {
  return (
    <>
      <ShowWhenSmaller breakpoint="medium">
        <LoadingBlockContainer variant="inline" width="40%" height="2.2rem" bottom="1.6rem" />
        <LoadingBlockContainer variant="inline" width="25%" height="1.8rem" bottom="0px" />
      </ShowWhenSmaller>
      <ShowAtOrLarger breakpoint="medium">
        <ShowWhenSmaller breakpoint="large">
          <LoadingBlockContainer variant="inline" width="40%" height="2.2rem" bottom="1.6rem" />
          <LoadingBlockContainer variant="inline" width="25%" height="1.8rem" bottom="0.45rem" />
        </ShowWhenSmaller>
        <ShowAtOrLarger breakpoint="large">
          <LoadingBlockContainer variant="inline" width="40%" height="3.2rem" bottom="0px" />
        </ShowAtOrLarger>
      </ShowAtOrLarger>
    </>
  )
}
