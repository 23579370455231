import { type ShowcaseThumbnailSizes } from '@/components/srp/showcase/Showcase'

export const autosListingMainImageUrlRuleSize = 400

export const autosShowcaseThumbnailSizes: ShowcaseThumbnailSizes = {
  xLarge: {
    width: 74.67,
    height: 56,
  },
  medium: {
    width: 64,
    height: 48,
  },
  mediumSmall: {
    width: 53.33,
    height: 40,
  },
  small: {
    width: 43,
    height: 32,
  },
}
