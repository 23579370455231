import {
  type AttributeFilter,
  type DateFilter,
  type FilterControlType,
  type RangeFilter,
} from '@kijiji/generated/graphql-types'
import { useTranslation } from 'next-i18next'

import { isRangeFilter, isStartEndType } from '@/types/search'

import { getLabelFromSelectedValue } from '../utils'

export type SelectedValueProps = {
  filter: AttributeFilter | RangeFilter | DateFilter
}

export const SelectedValue = ({ filter }: SelectedValueProps) => {
  const { t } = useTranslation('srp')

  const getDisplayValue = (filterType: FilterControlType) => {
    switch (filterType) {
      case 'DATE_RANGE': {
        if (!isRangeFilter(filter)) return null

        const selectedValues = filter.selectedRangeValues || []

        if (isStartEndType(selectedValues)) {
          const from = selectedValues.start
            ? `${t('filters.description.from')} ${selectedValues.start}`
            : ''

          /**
           * Should append "- " before the max value only if there is no start
           * ex: - to 10/01/2023
           */
          const to = selectedValues.end
            ? `${!from ? `- ` : ' '}${t('filters.description.to')} ${selectedValues.end}`
            : ''

          return `${from}${to}`
        }

        return null
      }
      case 'DATE':
        if ('selectedValue' in filter && filter.selectedValue) {
          return filter.selectedValue
        } else {
          return null
        }
      default:
        if (
          'selectedValues' in filter &&
          filter.selectedValues &&
          filter.selectedValues?.[0] !== '0'
        ) {
          return getLabelFromSelectedValue(filter.selectedValues[0], filter.values)
        } else {
          return null
        }
    }
  }

  return <>{getDisplayValue(filter.type)}</>
}
