import { useGetPopularRelatedKeywordsQuery } from '@kijiji/generated/graphql-types'
import { RESPONSE_LIMIT_DEFAULT } from 'next/dist/server/api-utils'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { ALL_CATEGORIES_ID_NUM } from '@/constants/category'
import { getUserLocationFromSearchQuery } from '@/domain/location/getUserLocationFromSearchQuery'
import { getSearchTypeWithKeywords } from '@/domain/search/getSearchTypeWithKeywords'
import { isDominantCategorySearch } from '@/domain/srp/isDominantCategorySearch'
import { getAppliedAttributeFilters } from '@/domain/srp/tracking/getAppliedAttributeFilters'
import { getAppliedPriceFilters } from '@/domain/srp/tracking/getAppliedPriceFilters'
import { getDataLayerListingsImpressions } from '@/domain/srp/tracking/getDataLayerListingsImpressions'
import { useGetSearchResultsData } from '@/hooks/srp/useGetSearchResultsData'
import { trackEvent, useTracking } from '@/lib/ga'
import { useTrackOnceOnLoad } from '@/lib/ga/hooks/useTrackOnceOnLoad'

import { GA_AD_TYPE_VALUE, isKeyOfGAAdType } from '../constants/ga'
import { GA_EVENT } from '../constants/gaEvent'

export type SearchPageDataLayer = {
  /** Category */
  c?: {
    c?: {
      /** Current Category Id */
      id: number
      /** Current category name */
      n: string
    }
    l1?: {
      /** Search's L1 category id */
      id: number
      /** Search's L1 category name */
      n: string
    }
    l2?: {
      /** Search's L2 category id */
      id: number
      /** Search's L2 category name */
      n: string
    }
    l3?: {
      /** Search's L3 category id */
      id: number
      /** Search's L3 category name */
      n: string
    }
  }
  /** Location */
  l?: {
    /** Search radius in kilometers */
    d?: number
    /** Postal code of center of radial search */
    pcid?: string
    /** Coordinates rounded to two decimal places */
    ltlng?: string
  }
  /** Search Parameters */
  s: {
    /** Dominant category search id */
    dc?: number
    /** Defined if the current search is a dominant category search */
    dcr?: boolean
    /** Page number */
    pn?: number
    /**
     * Defines how many organic results the user have in the current page
     * It should always be the same until the last page
     */
    ps?: number
    /** Total number of popular/related keywords loaded in the page. Can be null if keywords fail to load. */
    rk?: number | null
    /**
     * Sorting parameters
     */
    srt?: {
      /** Direction of the sorting */
      dr: 'ASC' | 'DESC'
      /** Sorting type (i.e. "Date") */
      f: string
    }
    /** Total number of pages in the search */
    tp?: number
    /** Total number of results for the search */
    tr?: number
    /** Keyword used for the search */
    kw?: string
    /** applied filter attributes */
    filters?: string
    /** MATCH SR FROM LEGACY */
    /** Search Result */
    sr?: {
      cr?: string
      /** Listing ID */
      id?: string
      /** Type of result - GA_AD_TYPE_VALUE*/
      rt?: string
      /** Position of the result */
      pos?: number
    }[]
  }
}

export type ListingsImpressions = {
  index: number
  item_badge?: string[]
  item_category1?: string
  item_category2?: string
  item_category3?: string
  item_category?: string
  item_id: string
  item_list_name: string
  item_name: string
  price?: number
  promotion_name: string
  quantity: number
}

export const useSrpTracking = () => {
  const {
    asPath,
    query: { gpTopAds },
  } = useRouter()

  const { data: { searchQuery, controls, pagination, results } = {} } = useGetSearchResultsData()
  const keywords = searchQuery?.keywords || ''
  const categoryId = searchQuery?.category?.id
  const offset = pagination?.offset || 0
  const limit = pagination?.limit || RESPONSE_LIMIT_DEFAULT
  const totalListings = pagination?.totalCount || 0
  const currentPage = (offset === 0 && limit === 0 ? 0 : offset / limit) + 1
  const filters = searchQuery?.filters
  const searchLocation = getUserLocationFromSearchQuery(searchQuery)

  const { data } = useGetPopularRelatedKeywordsQuery({
    fetchPolicy: 'cache-only',
    skip: !categoryId && categoryId !== ALL_CATEGORIES_ID_NUM,
    variables: {
      searchUrlPopularInput: {
        categoryId: `${categoryId}`,
        keywords,
        location: { id: searchLocation.id },
      },
    },
  })

  const pageType = getSearchTypeWithKeywords(keywords)

  const organicListings = results?.organic || []
  const topAdListings = results?.topAds || []
  const listings = [...topAdListings, ...organicListings]

  const totalPages = Math.ceil(listings.length / limit)

  const appliedAttributeFilters = getAppliedAttributeFilters(filters)
  const appliedPriceFilters = getAppliedPriceFilters(filters)

  let combinedFilters = ''

  if (appliedAttributeFilters && appliedPriceFilters) {
    combinedFilters = `${appliedAttributeFilters}; ${appliedPriceFilters}`
  } else {
    combinedFilters = appliedAttributeFilters || appliedPriceFilters
  }

  const dataLayer: SearchPageDataLayer = {
    s: {
      kw: searchQuery?.keywords || '',
      pn: currentPage,
      ps: listings.length,
      tp: totalPages,
      tr: totalListings,
      rk: data?.searchUrlsPopular?.length || 0,
      filters: combinedFilters,
    },
  }

  /** SEARCH RESULTS - LISTINGS */
  const dataLayerSearchResults: SearchPageDataLayer['s']['sr'] = listings.map((listing, index) => ({
    cr: '',
    id: listing.id,
    pos: index + 1,
    rt: isKeyOfGAAdType(listing.adSource)
      ? GA_AD_TYPE_VALUE[listing.adSource]
      : GA_AD_TYPE_VALUE.ORGANIC,
  }))
  dataLayer.s.sr = dataLayerSearchResults

  /** DOMINANT CATEGORY */
  const isDomCat = isDominantCategorySearch(asPath)
  dataLayer.s.dcr = isDomCat

  if (isDomCat) {
    dataLayer.s.dc = searchQuery?.category?.id
  }

  /** SORTING */
  const selectedSort = controls?.sorting.find((item) => item.isSelected)
  if (selectedSort) {
    dataLayer.s.srt = { dr: selectedSort.direction, f: selectedSort.by }
  }

  /** CATEGORY */
  if (searchQuery?.category) {
    let dataLayerCategory: SearchPageDataLayer['c'] = {}

    const category = searchQuery?.category

    dataLayerCategory = { c: { id: category.id, n: `${category.localizedName}` } }

    const paths = category.categoryPaths

    const l1Category = paths?.[0]
    const l2Category = paths?.[1]
    const l3Category = paths?.[2]

    if (l1Category) dataLayerCategory.l1 = { id: l1Category.id, n: `${l1Category.localizedName}` }
    if (l2Category) dataLayerCategory.l2 = { id: l2Category.id, n: `${l2Category.localizedName}` }
    if (l3Category) dataLayerCategory.l3 = { id: l3Category.id, n: `${l3Category.localizedName}` }

    dataLayer.c = dataLayerCategory
  }

  /** LOCATION */
  if (searchQuery?.location) {
    let dataLayerLocation: SearchPageDataLayer['l'] = {}

    dataLayerLocation = {}

    if (searchLocation.area) {
      const { latitude, longitude, radius } = searchLocation.area

      dataLayerLocation.d = radius
      dataLayerLocation.ltlng = `${latitude?.toFixed(2)},${longitude?.toFixed(2)}`
    }

    dataLayer.l = dataLayerLocation
  }

  useTracking({ data: dataLayer, pageType })

  /** LISTINGS IMPRESSIONS */
  const dataLayerEcommerce: ListingsImpressions[] = getDataLayerListingsImpressions({
    dataLayerCategory: dataLayer.c,
    listings,
    categoryId,
    isSeeAllTopAd: !!gpTopAds,
  })

  const trackViewPromotion = useCallback(() => {
    trackEvent({
      action: GA_EVENT.ViewPromotion,
      name: GA_EVENT.ViewPromotion,
      customParameters: { ecommerce: { items: dataLayerEcommerce } },
    })
  }, [dataLayerEcommerce])

  useTrackOnceOnLoad({
    triggerTracking: trackViewPromotion,
  })
}
