import { isAnyJobsCategory } from '@kijiji/category'
import {
  type AttributeFilter,
  type AttributeFilterInput,
  type DateFilter,
  type DateFilterInput,
  type DateRangeFilterInput,
  type FilterGroup,
  type MinMax,
  type RangeFilter,
  type RangeFilterInput,
  type ToggleFilter,
} from '@kijiji/generated/graphql-types'
import { formatWholeNumber } from '@kijiji/number/formatWholeNumber'

import { FILTER_CANONICAL } from '@/constants/search'
import {
  type AppliedFilters,
  type SearchFilter,
  type SearchFiltering,
  isAppliedAttributeFilter,
  isAppliedDateFilter,
  isAppliedDateRangeFilter,
  isAppliedRangeFilter,
  isAttributeFilter,
  stripTypenames,
} from '@/types/search'
import { priceFromCents } from '@/utils/kijiji-common/money'

import { type RouteLocale, ROUTE_LOCALE } from './locale'

export const formatTreeFacets = (
  totalResults?: number | null,
  routeLocale?: RouteLocale
): string => {
  /** The formatting of a whole number depends on the locale */
  const isEnglishLocale = routeLocale !== ROUTE_LOCALE.fr

  /** It shouldn't return the facets if is 0 results */
  return totalResults && totalResults > 0
    ? `(${formatWholeNumber(totalResults, isEnglishLocale)})`
    : ''
}

type FilterGroupsPrepped = {
  categoryTree: AttributeFilter | null
  locationTree: AttributeFilter | null
  filterGroups: FilterGroup[]
}

export const seperateTreesAndCleanFilters = (
  formFilters: SearchFiltering,
  selectedCategoryId?: number
): FilterGroupsPrepped => {
  return formFilters.reduce(
    (acc: FilterGroupsPrepped, item: FilterGroup) => {
      // Check if the item is the category or location tree
      if (item.name === FILTER_CANONICAL.CATEGORY_SECTION && isAttributeFilter(item.filters[0])) {
        acc.categoryTree = item.filters[0] || null
      } else if (
        item.name === FILTER_CANONICAL.LOCATION_SECTION &&
        isAttributeFilter(item.filters[0])
      ) {
        acc.locationTree = item.filters[0] || null
      } else if (
        // Don't want to render price range compoenent in Jobs category
        selectedCategoryId &&
        isAnyJobsCategory(selectedCategoryId) &&
        item.filters[0].name === FILTER_CANONICAL.PRICE_RANGE
      ) {
        return acc
      } else {
        // Add the filter group to the filterGroups array
        acc.filterGroups.push(item)
      }

      return acc
    },
    { categoryTree: null, locationTree: null, filterGroups: [] }
  )
}

/** Returns a list of attribute filters from the applied list */
export const getAttributeFromAppliedFilters = (
  appliedFilters: AppliedFilters
): AttributeFilterInput[] => {
  return stripTypenames(appliedFilters.filter(isAppliedAttributeFilter))
}

/** Returns a list of range filters from the applied list */
export const getRangeFromAppliedFilters = (appliedFilters: AppliedFilters): RangeFilterInput[] => {
  return stripTypenames(appliedFilters.filter(isAppliedRangeFilter))
}

/** Returns a list of date range filters from the applied list */
export const getDateRangeFromAppliedFilters = (
  appliedFilters: AppliedFilters
): DateRangeFilterInput[] => {
  return stripTypenames(appliedFilters.filter(isAppliedDateRangeFilter))
}
/** Returns a list of date filters from the applied list */
export const getDateFromAppliedFilters = (appliedFilters: AppliedFilters): DateFilterInput[] => {
  return stripTypenames(appliedFilters.filter(isAppliedDateFilter))
}

export const isFilterSelected = (searchFilter: SearchFilter) => {
  const selectedValuesExist = 'selectedValues' in searchFilter && searchFilter.selectedValues

  // A normal filter is considered selected if it has a selected value and that value is not '0'.
  // '0' is often used as the root value for a filter and as such is not considered selected.
  const isFilterSelectedWithSelectedValues =
    selectedValuesExist && searchFilter.selectedValues?.[0] !== '0'

  // A range filter is considered selected if it has a selected range value and is not undefined.
  const isFilterSelectedWithSelectedRangeValues =
    'selectedRangeValues' in searchFilter && searchFilter.selectedRangeValues

  return isFilterSelectedWithSelectedValues || isFilterSelectedWithSelectedRangeValues
}

// The location filter is considered selected if the selected value is present and not '0'
// 0 represents the root of the location tree, which includes all locations and as such is not filtered
export const isLocationFilterSelected = (locationTreeAttributeFilter: AttributeFilter) => {
  return locationTreeAttributeFilter?.selectedValues?.[0] !== '0'
}

/**
 * Function to update the date range filters constant whenever user submits a new filter
 *
 * @returns {updatedDateRange: DateRangeFilterInput[]} - Filters to be updates
 * @returns {isNewValue: boolean} - Defines if the updated filters is the same the the original filters or if any updates have been made
 */
export const updateDateRangeFilters = ({
  dateRangeFilters,
  end,
  filterName,
  start,
}: {
  dateRangeFilters: DateRangeFilterInput[]
  end?: DateRangeFilterInput['end']
  filterName: DateRangeFilterInput['filterName']
  start?: DateRangeFilterInput['start']
}): { updatedDateRange: DateRangeFilterInput[]; isNewValue: boolean } => {
  let updatedDateRange = [...dateRangeFilters]

  /** Check if the range to update is already in the array */
  const rangeToUpdate = dateRangeFilters.find((item) => item.filterName === filterName)

  /** Check if user is trying to reset the filters */
  const shouldClearFilter = !end && !start

  /** If the filter hasn't been added to the array - push to it */
  if (!rangeToUpdate) {
    if (shouldClearFilter) {
      return { updatedDateRange, isNewValue: false }
    }

    updatedDateRange.push({ filterName, start, end })
    return { updatedDateRange, isNewValue: true }
  }

  /** If the field has a value in the array - update the values */
  updatedDateRange = updatedDateRange.reduce(
    (acc: DateRangeFilterInput[], curr: DateRangeFilterInput) => {
      if (curr.filterName !== filterName) return [...acc, curr]

      if (shouldClearFilter) return acc

      return [...acc, { filterName: curr.filterName, start, end }]
    },
    []
  )

  const isNewValue = rangeToUpdate.end !== end || rangeToUpdate.start !== start
  return { updatedDateRange, isNewValue }
}

/**
 * Given a filter name, return the first child filter
 */
export const getChildFormFilter = ({
  filterName,
  formFilters,
}: {
  filterName: AttributeFilterInput['filterName']
  formFilters: SearchFiltering
}): AttributeFilter | RangeFilter | DateFilter | ToggleFilter | undefined => {
  return formFilters.find((formFilter) => formFilter.filters[0].parentName === filterName)
    ?.filters[0]
}

/**
 * Update array of attribute filters based on the new name/value pair selection
 */
export const updateAttributesFilters = ({
  attributeFilters,
  filterName,
  value,
  isSingleValueFilter,
}: {
  attributeFilters: AttributeFilterInput[]
  filterName: AttributeFilterInput['filterName']
  value: string | string[]
  isSingleValueFilter?: boolean
}): AttributeFilterInput[] => {
  //Define the default updatedFilters array as the current attribute filters array
  let updatedFilters = [...attributeFilters]

  /** Find the filter to update in the array containing all the current filter states */
  const filterToUpdate = attributeFilters.find((item) => item.filterName === filterName)
  const isValueAnArray = Array.isArray(value)

  if (filterToUpdate && (isValueAnArray ? !value.length : value === '')) {
    //If the value is empty, remove the filter from the attribute filter array
    updatedFilters = attributeFilters.filter((item) => item.filterName !== filterName)
    return updatedFilters
  }

  /** If the filter is not in the attribute filters array, add it */
  if (!filterToUpdate) {
    updatedFilters = [
      ...attributeFilters,
      { filterName, values: isValueAnArray ? [...value] : [value] },
    ]
    return updatedFilters
  }

  /**
   * If the filter already has a value, check if the filter accepts a single value or multiple values
   * */
  if (isSingleValueFilter) {
    /** Update the current filter value to the new one  */
    updatedFilters = attributeFilters.map((item) => {
      if (item.filterName !== filterName) return item

      const updatedValue = {
        filterName,
        values: isValueAnArray ? [...value] : [value],
      }
      return updatedValue
    })

    return updatedFilters
  }

  /**
   * If the filter accepts multiple values - check if the user is selecting or deselecting a new item
   */
  updatedFilters = attributeFilters.reduce(
    (acc: AttributeFilterInput[], curr: AttributeFilterInput) => {
      if (curr.filterName !== filterName) return [...acc, curr]

      // Deselect if the updated values is an array of values and the values includes any updated value
      if (isValueAnArray && curr.values.some((currVal) => value.includes(currVal))) {
        const newFilterValue = curr.values.filter((currVal) => !value.includes(currVal))
        if (!newFilterValue.length) return acc

        return [...acc, { filterName, values: [...newFilterValue] }]
      }

      // Deselect value if included in the array
      if (!isValueAnArray && curr.values.includes(value)) {
        // Remove from array
        curr.values.splice(curr.values.indexOf(value), 1)

        // Remove entire object if value being removed was the only one in the array
        if (!curr.values.length) return acc

        return [...acc, { filterName, values: curr.values }]
      }

      // Select new value if it was not included in the array
      const newFilterValue = [...curr.values, ...(isValueAnArray ? [...value] : [value])]

      return [...acc, { filterName, values: newFilterValue }]
    },
    []
  )

  return updatedFilters
}

/**
 * Returns the min-max selected price in dollars if is a "Price" filter
 */
export const getDollarRangeFromFilter = (rangeSelectedValues: MinMax) => {
  const minString = priceFromCents(rangeSelectedValues.min).dollars

  const maxDollarValue =
    rangeSelectedValues.max || rangeSelectedValues.max === 0
      ? priceFromCents(rangeSelectedValues.max).dollars
      : ''

  return {
    min: minString,
    max: maxDollarValue,
  }
}

/**
 * Returns the key used to get the localized label in case of special cases.
 *
 * 1] If we are getting the label of price, when searching for any job categories,
 * the label should be replaced by salary in the respective locale.
 */
export const getLocaleLabelKeyOverride = (
  filterName: string,
  selectedCategoryId: number
): string | null => {
  if (filterName === FILTER_CANONICAL.PRICE_RANGE && isAnyJobsCategory(selectedCategoryId))
    return 'salary'

  return null
}
/**
 * Returns the number of possible filter options if the filter values property exists.
 * @param attributeFilter an SRP filter of type AttributeFilter
 * @returns number - the number of filter options
 */
export const getAttributeFilterValuesCount = (attributeFilter?: AttributeFilter): number => {
  if (!attributeFilter || !attributeFilter?.values) return 0

  return attributeFilter.values.length
}
