import { type GptTargeting } from '@/lib/ads/types/adConfig'

/**
 * Removes empty objects and objects with an empty string value from an array of objects.
 *
 * @param {Array<ObjectWithKeys>} arr - The array of objects to remove objects with an empty string value from.
 * @returns {Array<ObjectWithKeys>} An array of ObjectWithKeys with no objects containing an empty string value.
 *
 * @typedef {Object} ObjectWithKeys
 * @property {string} [key] - A string key for the object.
 * @property {string} [value] - A value for the object.
 *
 * @example
 * const arr = [
 *   { key: 'foo', value: 'bar' },
 *   {},
 *   { key: 'baz', value: 'qux' },
 *   {},
 *   { key: 'quux', value: '' }
 * ];
 * const filteredArr = removeEmptyValues(arr);
 * console.log(filteredArr); // [{ key: 'foo', value: 'bar' }, { key: 'baz', value: 'qux' }]
 */
export function removeEmptyValues<T extends Record<string, unknown>>(
  arr: (T | GptTargeting)[]
): GptTargeting[] {
  return arr
    .filter((obj) => Object.keys(obj).length > 0) // Remove empty objects
    .filter(
      (obj) => !(Object.prototype.hasOwnProperty.call(obj, 'value') && obj.value === '')
    ) as GptTargeting[] // Remove objects with an empty string value
}
