import {
  ATVS_SNOWMOBILES_CATEGORIES,
  AUTOMOTIVE_SERVICES_CATEGORIES,
  BOATS_WATERCRAFT_CATEGORIES,
  CARS_VEHICLES_CATEGORIES,
  isAnyRealEstateCategory,
  isAutosCategory,
  MOTORCYCLES_CATEGORIES,
  RVS_CAMPERS_TRAILERS_CATEGORIES,
  VEHICLES_PART_ACCESSORIES_CATEGORIES,
} from '@kijiji/category'
import {
  JSON_LD_SCHEMA,
  JSON_LD_SCHEMA_TYPES,
  JSON_LD_SCHEMA_URLS,
  KIJIJI_LOGO,
} from '@kijiji/seo/constants'
import { type TFunction } from 'next-i18next'

import { getAttributesDictionary } from '@/components/srp/search-list/domain'
import { getRealEstateAttributes } from '@/components/srp/search-list/getRealEstateListingAttributes'
import {
  getAutosSeoAttributes,
  getAutosStructuredMarkup,
} from '@/components/srp/seo-metadata/structured-markup/getAutosStructuredMarkup'
import { getRealEstateStructuredMarkup } from '@/components/srp/seo-metadata/structured-markup/getRealEstateStructuredMarkup'
import { type Listing } from '@/types/search'
import { getBaseUrl } from '@/utils/url'

export const getProductSchemaType = (listingCategoryId: number, hasVin: boolean) => {
  const isVehicleParts = VEHICLES_PART_ACCESSORIES_CATEGORIES.includes(listingCategoryId)
  const isCar = isAutosCategory(listingCategoryId)
  const isRV = RVS_CAMPERS_TRAILERS_CATEGORIES.includes(listingCategoryId)
  const isMotorcycle = MOTORCYCLES_CATEGORIES.includes(listingCategoryId)
  const isHeavyEquipment = listingCategoryId === CARS_VEHICLES_CATEGORIES.HEAVY_EQUIPMENT
  const isBoatsWatercraft = BOATS_WATERCRAFT_CATEGORIES.includes(listingCategoryId)
  const isAtvSnowmobile = ATVS_SNOWMOBILES_CATEGORIES.includes(listingCategoryId)
  const isFarmingEquipment = listingCategoryId === CARS_VEHICLES_CATEGORIES.FARMING_EQUIPMENT
  const isAutomotiveServicesCategory = AUTOMOTIVE_SERVICES_CATEGORIES.includes(listingCategoryId)
  const isRealEstate = isAnyRealEstateCategory(listingCategoryId)

  switch (true) {
    case isRealEstate:
      return JSON_LD_SCHEMA_TYPES.REAL_ESTATE

    case isCar:
      return hasVin ? JSON_LD_SCHEMA_TYPES.CAR : JSON_LD_SCHEMA_TYPES.VEHICLE

    case isMotorcycle:
      return JSON_LD_SCHEMA_TYPES.MOTORCYCLE

    case isRV:
    case isBoatsWatercraft:
    case isAtvSnowmobile:
      return JSON_LD_SCHEMA_TYPES.VEHICLE

    case isAutomotiveServicesCategory:
      return JSON_LD_SCHEMA_TYPES.SERVICE

    case isVehicleParts:
    case isHeavyEquipment:
    case isFarmingEquipment:
    default:
      return JSON_LD_SCHEMA_TYPES.PRODUCT
  }
}

/**
 * currently only supports Autos and its subcategories
 * @param listings topAds + organic listings
 * @param t i18n translation function
 * @returns structured markup for each listing on the SRP
 */
export const getListingsStructuredMarkup = (listings: Listing[], t: TFunction) => {
  const itemListElement = listings.map((listing, index) => {
    const {
      title,
      description,
      imageUrls,
      price,
      seoUrl,
      categoryId: listingCategoryId,
      location,
    } = listing
    const mainImage = imageUrls?.[0]
    const attributesDictionary = getAttributesDictionary(listing.attributes)

    const attributes = getAutosSeoAttributes(attributesDictionary, t)
    const realEstateAttributes = getRealEstateAttributes(
      attributesDictionary,
      location.nearestIntersection
    )
    const companyLogo = attributesDictionary['company-logo']?.[0]

    const productSchemaType = getProductSchemaType(listingCategoryId, attributes.hasVin)

    const isVehicle =
      productSchemaType === JSON_LD_SCHEMA_TYPES.CAR ||
      productSchemaType === JSON_LD_SCHEMA_TYPES.MOTORCYCLE ||
      productSchemaType === JSON_LD_SCHEMA_TYPES.VEHICLE

    const isRealEstate = productSchemaType === JSON_LD_SCHEMA_TYPES.REAL_ESTATE

    const isService = productSchemaType === JSON_LD_SCHEMA_TYPES.SERVICE

    const structuredMarkup = {
      // minimum required properties for product snippets, merchant listings & services
      '@context': JSON_LD_SCHEMA_URLS.BASE,
      '@type': productSchemaType,
      name: title,
      description,
      image: mainImage || KIJIJI_LOGO,
      offers:
        !isService && !isRealEstate
          ? {
              '@type': 'Offer',
              availability: `${JSON_LD_SCHEMA}/InStock`,
              price: price.amount,
              priceCurrency: 'CAD',
            }
          : null,
      url: `${getBaseUrl()}${seoUrl}`,
      logo: companyLogo, // some listings have a company logo in the attributes

      // additional properties applicable only to vehicle & car listings
      ...(isVehicle && { ...getAutosStructuredMarkup(attributes) }),
      ...(isRealEstate && { ...getRealEstateStructuredMarkup(realEstateAttributes, location) }),
    }

    return {
      position: index + 1,
      item: structuredMarkup,
      '@type': 'ListItem',
    }
  })

  return itemListElement
}
