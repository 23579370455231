import { useEffect, useRef, useState } from 'react'

import { AD_REFRESH_COOLDOWN_LEGACY, GPT_COMPONENT_ID } from '@/lib/ads/constants/adSlots'
import { triggerManualAdRefresh } from '@/lib/ads/utils/triggerManualAdRefresh'

export const useScrollAway = (scrollDistance = 950) => {
  const [scrollAwayTriggerDistance, setScrollAwayTriggerDistance] = useState(scrollDistance)

  const [scrollAway, setShouldScrollAway] = useState(false)
  const [isSticky, setIsSticky] = useState(false)

  const shouldTopLeaderboardRefresh = useRef<boolean>(false)
  const recentlyRefreshed = useRef<boolean>(false)

  const setRefreshTimer = () => {
    recentlyRefreshed.current = true
    return setTimeout(() => {
      recentlyRefreshed.current = false
    }, AD_REFRESH_COOLDOWN_LEGACY)
  }

  const checkScroll = () => {
    if (window.scrollY >= scrollAwayTriggerDistance) {
      setShouldScrollAway(true)
    } else {
      setShouldScrollAway(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', checkScroll)

    const header = document.querySelector('#global-header')

    if (header) {
      // Get the scroll distance from the header so we account for any added banner on top of the page
      const headerRect = header.getBoundingClientRect()
      const headerHeight = headerRect.bottom + window.scrollY

      setScrollAwayTriggerDistance(headerHeight)
    }

    return () => {
      window.removeEventListener('scroll', checkScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleInViewChange = (inView: boolean) => {
    setIsSticky(!inView)

    // refresh top leaderboard when user scrolls down past it and then back up to the top
    if (shouldTopLeaderboardRefresh.current && !recentlyRefreshed.current && inView) {
      shouldTopLeaderboardRefresh.current = false
      triggerManualAdRefresh(GPT_COMPONENT_ID.TOP_LEADERBOARD)
      setRefreshTimer()
    } else {
      shouldTopLeaderboardRefresh.current = true
    }
  }

  return { isSticky, scrollAway, handleInViewChange }
}
