import { type ThemeProps, theme } from '@kijiji/theme'
import { type EmblaOptionsType } from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'
import { useTranslation } from 'next-i18next'
import { useEffect } from 'react'
import { useTheme } from 'styled-components'

import { Divider } from '@/ui/atoms/divider'
import { getBaseUrl } from '@/utils/url'

import {
  DEFAULT_THUMBNAIL_SIZE,
  ShowcaseContainer,
  ShowcaseItem,
  ShowcaseList,
  ShowcasePicture,
  ShowcasePictureWrapper,
  ShowcaseViewport,
} from './styled'

type ThumbnailSize = {
  width: number
  height: number
}

export type ShowcaseThumbnailSizes = Record<
  Extract<keyof ThemeProps['breakpoints'], 'xLarge' | 'medium' | 'mediumSmall' | 'small'>,
  ThumbnailSize
>

export type ShowcaseProps = {
  showcaseImages: string[]
  seoUrl: string
  minNumberOfPictures?: number
  maxNumberOfPictures?: number
  itemBorderRadius?: keyof ThemeProps['borderRadius']
  thumbnailSizes?: ShowcaseThumbnailSizes
  itemListGap?: keyof ThemeProps['spacing']
  handleListingCardClick: (photoshowcaseClick?: boolean) => void
  showDivider?: boolean
  baseAltText?: string
}

const defaultThumbnailSizes: ShowcaseThumbnailSizes = {
  xLarge: {
    width: DEFAULT_THUMBNAIL_SIZE,
    height: DEFAULT_THUMBNAIL_SIZE,
  },
  medium: {
    width: DEFAULT_THUMBNAIL_SIZE,
    height: DEFAULT_THUMBNAIL_SIZE,
  },
  mediumSmall: {
    width: DEFAULT_THUMBNAIL_SIZE,
    height: DEFAULT_THUMBNAIL_SIZE,
  },
  small: {
    width: DEFAULT_THUMBNAIL_SIZE,
    height: DEFAULT_THUMBNAIL_SIZE,
  },
}

export const Showcase = ({
  showcaseImages,
  seoUrl,
  handleListingCardClick,
  minNumberOfPictures = 2,
  maxNumberOfPictures = 8,
  itemBorderRadius,
  thumbnailSizes = defaultThumbnailSizes,
  itemListGap = 'default',
  showDivider = true,
  baseAltText,
}: ShowcaseProps) => {
  const { breakpoints } = useTheme()
  const { t } = useTranslation('srp')
  const tabletBreakpoint = `(min-width: ${breakpoints.medium}px)`
  const minNbrPictures = minNumberOfPictures
  const maxNbrPictures = maxNumberOfPictures

  const showcaseOptions: EmblaOptionsType = {
    active: true,
    dragFree: true,
    containScroll: 'trimSnaps',
    breakpoints: {
      [tabletBreakpoint]: { active: false },
    },
  }

  const [emblaRef, emblaApi] = useEmblaCarousel(showcaseOptions, [WheelGesturesPlugin()])

  showcaseImages = showcaseImages.slice(0, maxNbrPictures)

  useEffect(() => {
    if (!emblaApi) return

    emblaApi.reInit()
  }, [emblaApi, showcaseImages])

  const getShowcaseUrl = (imageNumber: number) => {
    const url = new URL(seoUrl, getBaseUrl())
    url.searchParams.set('imageNumber', imageNumber.toString())
    return url.toString()
  }

  const getAltText = (index: number) => {
    return baseAltText ? `${baseAltText} (image ${index + 1})` : ''
  }

  return showcaseImages?.length > minNbrPictures ? (
    <ShowcaseContainer data-testid="showcase">
      {showDivider && <Divider bottom={theme.spacing.defaultSmall} />}

      <ShowcaseViewport ref={emblaRef}>
        <ShowcaseList gap={itemListGap}>
          {showcaseImages?.map((image, i) => (
            <ShowcaseItem key={i} borderRadius={itemBorderRadius} thumbnailSizes={thumbnailSizes}>
              <a
                href={getShowcaseUrl(i + 1)}
                data-testid={`showcase-thumbnail${i}`}
                aria-label={`${t('listing.showcase.image_link', { index: i })}`}
                onClick={() => handleListingCardClick(true)}
              >
                <ShowcasePictureWrapper thumbnailSizes={thumbnailSizes}>
                  <ShowcasePicture src={image} fill alt={getAltText(i)} />
                </ShowcasePictureWrapper>
              </a>
            </ShowcaseItem>
          ))}
        </ShowcaseList>
      </ShowcaseViewport>
    </ShowcaseContainer>
  ) : null
}
