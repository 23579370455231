export type SearchAccordionConfig = {
  expandedByDefault: {
    general: string[]
    category: { [id: string]: string[] }
  }
}

export const EXPAND_FILTERS: SearchAccordionConfig['expandedByDefault'] = {
  general: ['price', 'categorySection'],
  category: { 174: ['carmake', 'carmodel', 'caryear', 'carbodytype'] },
}
