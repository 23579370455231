import { useTranslation } from 'next-i18next'
import { useTheme } from 'styled-components'

import { GPT_COMPONENT_ID } from '@/lib/ads/constants/adSlots'

import { SrpLoading } from '../../srp-loading'
import { FiltersAccordion } from '../FiltersAccordion/FiltersAccordion'
import { FiltersDivider } from '../styled'
import { FiltersSidebarPanel, FiltersSidebarTitle, SkyscraperAdSlot } from './styled'

export type FiltersSidebarProps = {
  isMobileForAds?: boolean
  isLoadingFilters?: boolean
}

export const FiltersSidebar = ({ isMobileForAds, isLoadingFilters }: FiltersSidebarProps) => {
  const { t } = useTranslation('srp')
  const { colors } = useTheme()

  return (
    <div>
      <FiltersSidebarPanel data-testid="srp-desktop-filter">
        <FiltersSidebarTitle color={colors.grey.primary} as="h2">
          {t('filters.title')}
        </FiltersSidebarTitle>
        <FiltersDivider />

        {isLoadingFilters ? <SrpLoading variant="sidebar-filter" /> : <FiltersAccordion />}
      </FiltersSidebarPanel>

      {!isMobileForAds && (
        <SkyscraperAdSlot
          id={GPT_COMPONENT_ID.SKYSCRAPER}
          data-testid={GPT_COMPONENT_ID.SKYSCRAPER}
        />
      )}
    </div>
  )
}
