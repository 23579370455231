import EntryMileageIcon from '@kijiji/icons/src/icons/EntryMileage'
import FuelIcon from '@kijiji/icons/src/icons/Fuel'
import LocationSolidIcon from '@kijiji/icons/src/icons/LocationSolid'
import TransmissionIcon from '@kijiji/icons/src/icons/Transmission'
import { formatWholeNumber } from '@kijiji/number/formatWholeNumber'
import { useTranslation } from 'next-i18next'
import { type Key, type ReactElement } from 'react'
import { useTheme } from 'styled-components'

import { ShowAtOrLarger, ShowWhenSmaller } from '@/components/shared/breakpoint'
import { AUTOS_ATTRIBUTES } from '@/components/srp/search-list/domain'
import { ROUTE_LOCALE, useLocale } from '@/hooks/useLocale'
import { Flex } from '@/ui/atoms/flex'
import { capitalizeString } from '@/utils/string'

import { AutosAttributeListItem } from '../autos-attribute-list/AutosAttributeListItem'
import { AutosCrossPromoListingAttributesList } from './styled'

type AutosCrossPromoListingAttributesProps = {
  /**
   * Listing attributes
   */
  attributes: Record<string, string[]>
  /**
   * Listing location
   */
  locationName?: string | null
}

type CarAttribute = {
  label: string
  value: string
  icon: ReactElement
  hiddenOnSmallerThanMediumBreakpoint: boolean
}

export const AutosCrossPromoListingAttributes = ({
  attributes,
  locationName,
}: AutosCrossPromoListingAttributesProps) => {
  const { t } = useTranslation('srp')
  const theme = useTheme()
  const { routeLocale } = useLocale()

  const kilometers = attributes[AUTOS_ATTRIBUTES.KILOMETERS]?.[0]
  const transmission = attributes[AUTOS_ATTRIBUTES.TRANSMISSION]?.[0]

  const transmissionLocaleKeyMap: Record<string, string> = {
    1: 'listing.attributes.car_transmission.manual',
    2: 'listing.attributes.car_transmission.automatic',
  }

  const formattedKilometers = kilometers
    ? formatWholeNumber(Number(kilometers), routeLocale === ROUTE_LOCALE.en)
    : undefined
  const transmissionLocaleKey = transmission ? transmissionLocaleKeyMap[transmission] : undefined
  const fuelType = attributes[AUTOS_ATTRIBUTES.FUEL_TYPE]?.[0]

  const carAttributes: CarAttribute[] = [
    ...(formattedKilometers
      ? [
          {
            label: t('listing.attributes.kilometers.label'),
            value: t('listing.attributes.kilometers.value', { kms: formattedKilometers }),
            icon: <EntryMileageIcon size="default" color={theme.colors.grey.primary} />,
            hiddenOnSmallerThanMediumBreakpoint: false,
          },
        ]
      : []),
    ...(transmissionLocaleKey
      ? [
          {
            label: t('listing.attributes.car_transmission.label'),
            value: t(transmissionLocaleKey),
            hiddenOnSmallerThanMediumBreakpoint: true,
            icon: <TransmissionIcon size="default" color={theme.colors.grey.primary} />,
          },
        ]
      : []),
    ...(fuelType
      ? [
          {
            label: t('listing.attributes.fuel_type.label'),
            value: capitalizeString(fuelType),
            hiddenOnSmallerThanMediumBreakpoint: true,
            icon: <FuelIcon size="default" color={theme.colors.grey.primary} />,
          },
        ]
      : []),
    ...(locationName
      ? [
          {
            label: t('listing.attributes.location'),
            value: locationName,
            icon: <LocationSolidIcon size="default" color={theme.colors.grey.primary} />,
            hiddenOnSmallerThanMediumBreakpoint: false,
          },
        ]
      : []),
  ]

  const renderAttribute = (attribute: CarAttribute, key: Key) => {
    if (!attribute) return null
    return <AutosAttributeListItem key={key} icon={attribute.icon} text={attribute.value} />
  }

  return (
    <Flex flexGrow={1}>
      <ShowAtOrLarger breakpoint="medium">
        <AutosCrossPromoListingAttributesList>
          {carAttributes.map((attribute) => {
            const key = `${attribute.label}-large`
            return renderAttribute(attribute, key)
          })}
        </AutosCrossPromoListingAttributesList>
      </ShowAtOrLarger>
      <ShowWhenSmaller breakpoint="medium">
        <AutosCrossPromoListingAttributesList>
          {carAttributes
            .filter((attribute) => attribute && !attribute.hiddenOnSmallerThanMediumBreakpoint)
            .map((attribute) => {
              const key = `${attribute.label}-belowMedium`
              return renderAttribute(attribute, key)
            })}
        </AutosCrossPromoListingAttributesList>
      </ShowWhenSmaller>
    </Flex>
  )
}
