import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import { useTheme } from 'styled-components'

import { BodyText } from '@/ui/atoms/body-text'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { Spacing } from '@/ui/atoms/spacing'

import { SrpErrorContainer, SrpErrorImageContainer } from './styled'

export const SrpError = () => {
  const { t } = useTranslation('srp')
  const { spacing, colors } = useTheme()

  return (
    <SrpErrorContainer>
      <Spacing mTop={spacing.husky}>
        <SrpErrorImageContainer>
          <Image alt="" fill src="/next-assets/images/deleteAccount.svg" />
        </SrpErrorImageContainer>
      </Spacing>

      <Spacing mBottom={spacing.default} mTop={spacing.large}>
        <HeadlineText color={colors.grey.primary}>
          {t('errors.loading_results.header')}
        </HeadlineText>
      </Spacing>

      <BodyText color={colors.grey.light1} textAlign="center">
        {t('errors.loading_results.body')}
      </BodyText>
    </SrpErrorContainer>
  )
}
