import { isAnyVacationRentalsCategory } from '@kijiji/category'
import {
  type AppliedAttributeFilter,
  type AppliedDateFilter,
  type AppliedDateRangeFilter,
  type AppliedFilter,
  type AppliedRangeFilter,
  type AttributeFilter,
} from '@kijiji/generated/graphql-types'
import { type FC, useCallback } from 'react'

import { ALL_CATEGORIES_ID_NUM } from '@/constants/category'
import { ALL_CANADA_LOCATION_ID } from '@/constants/location'
import { seperateTreesAndCleanFilters } from '@/domain/filters'
import { getExpandedFilterIdList } from '@/domain/srp/filters/getExpandedFilterIdList'
import { type ParentFilter } from '@/domain/srp/filters/getParentFilter'
import { useGetSearchResultsData } from '@/hooks/srp/useGetSearchResultsData'
import {
  type FilterKeysEnum,
  type RefetchResultsType,
  useSearchActions,
} from '@/hooks/srp/useSearchActions'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { Accordion } from '@/ui/atoms/accordion'
import { type NullableFields } from '@/utils/types'

import { CategoryTreeFilter } from '../filter-types/CategoryTreeFilter'
import { LocationTreeFilter } from '../filter-types/LocationTreeFilter'
import { FiltersDivider } from '../styled'
import { FilterGroup as FilterGroupComponent } from './FilterGroup'

export type FilterProps<T, V> = {
  filter: T
  refetch: (args: AppliedFilter & NullableFields<V>, trackingLabel?: string) => void
  parentFilter?: ParentFilter
  isMobile?: boolean
  isSrpLoading?: boolean
}

export type TreeFilterProps = {
  filter: AttributeFilter
  selectedId?: number
  refetch: RefetchResultsType
}

export type FilterValuesUnion =
  | NullableFields<Omit<AppliedAttributeFilter, 'filterName'>>
  | NullableFields<Omit<AppliedDateFilter, 'filterName'>>
  | NullableFields<Omit<AppliedDateRangeFilter, 'filterName'>>
  | NullableFields<Omit<AppliedRangeFilter, 'filterName'>>

export type FilterRefetchResults<A> = (
  type: FilterKeysEnum,
  filterName: string,
  value: A,
  trackingLabel?: string
) => void

export const FiltersAccordion: FC<{ isMobile?: boolean }> = ({ isMobile }) => {
  const { data } = useGetSearchResultsData()
  const { controls, searchQuery } = data || {}
  const { refetchResults } = useSearchActions()

  const filters = controls?.filtering || []
  const selectedCategoryId = searchQuery?.category?.id || ALL_CATEGORIES_ID_NUM
  const selectedLocationId = searchQuery?.location?.id || ALL_CANADA_LOCATION_ID

  const preExpandedFilters = isMobile ? [] : getExpandedFilterIdList(selectedCategoryId)

  const refetchResultsHandler: FilterRefetchResults<FilterValuesUnion> = useCallback(
    async (type, filterName, value, trackingLabel) => {
      refetchResults(
        { [type]: [{ filterName, ...value }] },
        {
          debounce: true,
          event: trackingLabel
            ? { action: GA_EVENT.FilterSelect, label: trackingLabel }
            : undefined,
        }
      )
    },
    [refetchResults]
  )

  const { categoryTree, locationTree, filterGroups } = seperateTreesAndCleanFilters(
    filters,
    selectedCategoryId
  )

  return (
    <>
      <Accordion gap={0} preExpanded={preExpandedFilters}>
        {categoryTree && (
          <CategoryTreeFilter
            filter={categoryTree}
            selectedId={selectedCategoryId}
            refetch={refetchResults}
          />
        )}

        <FiltersDivider />

        {locationTree && !isAnyVacationRentalsCategory(selectedCategoryId) && (
          <LocationTreeFilter
            filter={locationTree}
            selectedId={selectedLocationId}
            refetch={refetchResults}
          />
        )}

        {filterGroups.map((item, index) => {
          return (
            <FilterGroupComponent filterGroup={item} key={index} refetch={refetchResultsHandler} />
          )
        })}
      </Accordion>
    </>
  )
}
