import { theme } from '@kijiji/theme'
import { type MutableRefObject } from 'react'

import { AD_REFRESH_COOLDOWN_EXTENDED, GPT_COMPONENT_ID } from '@/lib/ads/constants/adSlots'
import { isServer } from '@/utils/isSSR'

import { triggerManualAdRefresh } from './triggerManualAdRefresh'

export const handleScrollingAdRefresh = (
  inView: boolean,
  recentlyRefreshed: MutableRefObject<boolean>
) => {
  if (isServer() || recentlyRefreshed.current) return

  const isLargeScreen = window.innerWidth >= theme.breakpoints.large
  const isSmallScreen = window.innerWidth < theme.breakpoints.small

  // refresh skyscraper every mweb leaderboard 20 listings when scrolling
  const skyscraperNeedsAdRefresh = inView && isLargeScreen
  const mwebLeaderboardNeedsAdRefresh = inView && isSmallScreen

  if (mwebLeaderboardNeedsAdRefresh) triggerManualAdRefresh(GPT_COMPONENT_ID.MWEB_LEADERBOARD)

  if (skyscraperNeedsAdRefresh) triggerManualAdRefresh(GPT_COMPONENT_ID.SKYSCRAPER)

  // prevent ad refreshes from happening too frequently
  if (mwebLeaderboardNeedsAdRefresh || skyscraperNeedsAdRefresh) {
    recentlyRefreshed.current = true
    setTimeout(() => {
      recentlyRefreshed.current = false
    }, AD_REFRESH_COOLDOWN_EXTENDED)
  }
}
