import { AutosBadgeCarFax } from './AutosBadgeCarFax'
import { AutosBadgeCertifiedPreOwner } from './AutosBadgeCertifiedPreOwner'
import { AutosBadgeContactless } from './AutosBadgeContactless'
import { AutosBadgeFinancing } from './AutosBadgeFinancing'
import { AutosBadgesContainer } from './styled'

export type AutosBadgesProps = {
  testId?: string
  children?: React.ReactNode
  horizontalOverflow?: boolean
  hasDescription?: boolean
}

const AutosBadges = ({
  testId = 'listing-autos-badges',
  horizontalOverflow = false,
  children,
  hasDescription = false,
}: AutosBadgesProps) => {
  if (!children) return null
  return (
    <AutosBadgesContainer
      data-testid={testId}
      $horizontalOverflow={horizontalOverflow}
      hasDescription={hasDescription}
    >
      {children}
    </AutosBadgesContainer>
  )
}

AutosBadges.CarFax = AutosBadgeCarFax
AutosBadges.CertifiedPreOwned = AutosBadgeCertifiedPreOwner
AutosBadges.Financing = AutosBadgeFinancing
AutosBadges.Contactless = AutosBadgeContactless

export { AutosBadges }
