import { type FC, type PropsWithChildren } from 'react'

import { UserActionFeedbackContainer } from './styled'

/**
 * Shared component to display user action feedback on the bottom of the page
 * This component is a wrapper to be used in different contexts of the application
 *
 * The children component will decide what to be displayed inside of this toast
 * As well as when this toast should show or disappear
 */
export const UserActionFeedback: FC<PropsWithChildren> = ({ children }) => {
  return <UserActionFeedbackContainer>{children}</UserActionFeedbackContainer>
}
