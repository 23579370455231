import { getAdSourceForPromotionListing } from '@/components/srp/search-list/domain'
import { isDealerListing } from '@/domain/listings/isDealerListing'
import { getSearchTypeWithKeywords } from '@/domain/search/getSearchTypeWithKeywords'
import { trackEvent } from '@/lib/ga'
import { GA_AD_TYPE_VALUE, isKeyOfGAAdType } from '@/lib/ga/constants/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { type Listing } from '@/types/search'

import { getListingFlags } from './getListingFlags'

type TrackEventListingClickProps = {
  isSeeAllTopAd?: boolean
  keywords?: string
  listing: Listing
  position: number
  photoshowcaseClick?: boolean
}

/**
 * Push this user triggered event to the data layer
 * This correlated to "ResultsAdClick" on legacy. It is called select_promotion on NWA.
 * This event is triggered when a user clicks on a listing to go to its VIP
 */
export const trackEventListingClick = ({
  isSeeAllTopAd,
  keywords,
  listing,
  position,
  photoshowcaseClick,
}: TrackEventListingClickProps) => {
  const { adSource, id, price, title, flags } = listing

  const adSourcePromoListing =
    isKeyOfGAAdType(getAdSourceForPromotionListing(flags)) &&
    getAdSourceForPromotionListing(flags, adSource, isSeeAllTopAd)

  const flagValues = getListingFlags(listing)
  const itemBadge = flagValues.length > 0 ? { item_badge: flagValues } : {}
  const listingAdSource = adSourcePromoListing || adSource
  const isDealer = isDealerListing(listing)

  const promotionName = {
    promotion_name: isKeyOfGAAdType(listingAdSource)
      ? GA_AD_TYPE_VALUE[listingAdSource]
      : GA_AD_TYPE_VALUE.ORGANIC,
  }

  const itemListName = { item_list_name: getSearchTypeWithKeywords(keywords) }

  const item = {
    item_id: id,
    item_name: title,
    index: position,
    quantity: 1,
    price: price?.amount,
    ...promotionName,
    ...itemListName,
    ...itemBadge,
  }

  trackEvent({
    action: GA_EVENT.SelectPromotion,
    name: GA_EVENT.SelectPromotion,
    label: `partner=${adSource};partneradid:${id};position:${position};isDealerListing=${isDealer};PhotoshowcaseClick=${
      // to protect against event object getting passed down instead of boolean
      photoshowcaseClick === true ? '1' : '0'
    }`,
    customParameters: { ecommerce: { items: [item] } },
  })
}
