import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import React, { type FC, useMemo, useState } from 'react'

import { LazyRender } from '@/components/shared/lazy-render'
import { mountPaginationUrl } from '@/domain/urls'
import { type PaginationProps } from '@/ui/molecules/pagination'
import { getBaseUrl } from '@/utils/url'

import { SimplePagination } from './SimplePagination'
import { PaginationContainer } from './styled'

/**
 * Dynamically imported full pagination component.
 * This allows for code-splitting and reduces the initial bundle size.
 */
const DynamicPagination = dynamic<PaginationProps>(
  () => import('@/ui/molecules/pagination').then((mod) => mod.Pagination),
  { ssr: false }
)

export type SrpPaginationProps = Pick<PaginationProps, 'totalPageCount' | 'selectedIndex'>

/**
 * PaginationWrapper component
 *
 * This component optimizes the pagination rendering for both SEO and performance:
 * 1. It initially renders a SimplePagination component, which includes essential links for SEO.
 * 2. It then lazy-loads the full pagination component when it becomes visible in the viewport.
 * 3. Once the full pagination is loaded, it replaces the simple version, providing full functionality.
 *
 * This approach ensures that search engines can crawl the essential pagination links
 * while still benefiting from the performance advantages of lazy-loading the full component.
 */
const PaginationWrapper: FC<PaginationProps> = (props) => {
  const [isFullPaginationLoaded, setIsFullPaginationLoaded] = useState(false)

  return (
    <>
      {!isFullPaginationLoaded && <SimplePagination {...props} />}
      <LazyRender threshold={0.1} onVisible={() => setIsFullPaginationLoaded(true)}>
        {isFullPaginationLoaded && <DynamicPagination {...props} />}
      </LazyRender>
    </>
  )
}

/**
 * SrpPagination component
 *
 * This component renders the pagination for Search Results Pages (SRP).
 * It utilizes the PaginationWrapper to provide an optimized pagination experience.
 *
 * @param {number} totalPageCount - The total number of pages in the pagination.
 * @param {number} selectedIndex - The currently selected page number.
 */
export const SrpPagination: FC<SrpPaginationProps> = ({ totalPageCount, selectedIndex }) => {
  const { t } = useTranslation('srp')
  const { asPath } = useRouter()

  /**
   * Generates the URL for a given page number.
   * This function is memoized to prevent unnecessary recalculations.
   */
  const generateUrl = useMemo(
    () => (page: number) => {
      let baseUrl = getBaseUrl()

      // Remove last "/" from base URL to prevent redirect url to be mounted as: `kijiji.ca//b-buy-sell...`
      if (baseUrl?.endsWith('/')) {
        baseUrl = baseUrl.substring(0, baseUrl.length - 1)
      }
      const url = `${baseUrl}${asPath}`
      const href = mountPaginationUrl(url, page)
      return { href }
    },
    [asPath]
  )

  const paginationProps = {
    generateUrl,
    label: t('pagination.label'),
    nextLabel: t('pagination.next'),
    pageNumberLabel: t('pagination.go_to_page'),
    previousLabel: t('pagination.previous'),
    selectedIndex,
    totalPageCount,
  }

  return (
    <PaginationContainer data-testid="srp-pagination">
      <PaginationWrapper {...paginationProps} />
    </PaginationContainer>
  )
}
