import { sendToLogger } from '@/utils/sendToLogger'

import { HighlightedKeyword } from './styled'

/**
 *
 * @param text title or description of the listing
 * @param keyword global search keyword
 * @returns text with the keyword wrapped in <strong> tag; if keyword is not present, return the original text
 */
export const highlightKeywords = (listingId: string, text: string, keyword?: string) => {
  if (!keyword) return text

  try {
    /**
     * Remove special characters in the keyword to prevent regex injection
     */
    const sanitizedKeyword = keyword.replace(/[.*+?^${}()|[\]\\/]/gi, '')

    /**
     * Split text into parts that contain the keyword and parts that don't
     * eg keyword = "cake", text = "I like cake and coding is a cakewalk"
     * parts = ["I like ", "cake", " and coding is a ", "cakewalk"]
     */
    const textParts = text.split(new RegExp(`(\\b\\w*${sanitizedKeyword}\\w*\\b)`, 'gi'))

    // if part includes the keyword, wrap it with HighlightedKeyword (<strong>)
    return textParts.map((part, index) => {
      const containsKeyword = part.toLowerCase().includes(sanitizedKeyword.toLowerCase())
      return containsKeyword ? (
        <HighlightedKeyword key={`kw-${listingId}-${index}`}>{part}</HighlightedKeyword>
      ) : (
        part
      )
    })
  } catch (error) {
    sendToLogger(error, { fingerprint: ['highlightKeywords'] })
    return text
  }
}
