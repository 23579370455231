import { AdobeAudienceManager } from '@/lib/ads/components/adobe-audience-manager'
import { AdSenseScripts } from '@/lib/ads/components/adsense/AdSenseScripts'
import { AmazonTamScripts } from '@/lib/ads/components/amazon-tam/amazonTamScripts'
import { BlockthroughScripts } from '@/lib/ads/components/blockthrough/BlockthroughScripts'
import { GptScripts } from '@/lib/ads/components/gpt/GptScripts'
import { PrebidScripts } from '@/lib/ads/components/prebid/PrebidScripts'
import { type GptTargeting } from '@/lib/ads/types/adConfig'

type AdvertisingScriptsProps = {
  gpt: GptTargeting[]
  handleGptReady: () => void
}

/**
 * @deprecated It is only being used for SRP. Should be removed and utilize the AdWrapper as the other pages
 */
export const AdvertisingScripts = ({ gpt, handleGptReady }: AdvertisingScriptsProps) => {
  return (
    <>
      <GptScripts handleGptReady={handleGptReady} />

      <PrebidScripts />

      <AmazonTamScripts />

      <AdobeAudienceManager gpt={gpt} />

      <AdSenseScripts />

      <BlockthroughScripts />
    </>
  )
}
