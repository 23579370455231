import { isMultipleOfTwenty } from '@kijiji/number/isMultipleOfTwenty'
import { type PropsWithChildren, useRef } from 'react'
import { InView } from 'react-intersection-observer'

import { useGetSearchResultsData } from '@/hooks/srp/useGetSearchResultsData'
import { useOneTimeSaveSearch } from '@/hooks/useOneTimeSaveSearch'
import { handleScrollingAdRefresh } from '@/lib/ads/utils/handleScrollingAdRefresh'

import { getCurrentPageFromPagination } from './getCurrentPageFromPaginationData'

export type SearchListIntersectionObserverProps = PropsWithChildren<{
  index: number
}>

/**
 * A wrapper component for adding IntersectionObserver to specific ListingCards
 * i.e. the 10th and every 20th ListingCard
 * @param props
 * @returns wrapped JSX.Element
 */
export const SearchListIntersectionObserver = ({
  index,
  children,
  ...props
}: SearchListIntersectionObserverProps) => {
  const { data } = useGetSearchResultsData()
  const { searchString = '' } = data?.searchQuery || {}
  const currentPage = getCurrentPageFromPagination(data?.pagination || {})

  const { handleOneTimeSaveSearch } = useOneTimeSaveSearch(searchString)
  const recentlyRefreshed = useRef(false)

  /** It should trigger the one-time-save-search functionality when the 10th listing is in view */
  const indexIsTen = index === 9
  /** It should trigger ad refreshes if listing index is a multiple of 20 */
  const indexIsMultipleOfTwenty = isMultipleOfTwenty(index + 1)

  return indexIsTen ? (
    <InView onChange={(inView) => handleOneTimeSaveSearch({ inView, index, page: currentPage })}>
      {({ ref }) => (
        <li ref={ref} {...props}>
          {children}
        </li>
      )}
    </InView>
  ) : indexIsMultipleOfTwenty ? (
    <InView onChange={(inView) => handleScrollingAdRefresh(inView, recentlyRefreshed)}>
      {({ ref }) => (
        <li ref={ref} {...props}>
          {children}
        </li>
      )}
    </InView>
  ) : (
    <li {...props}>{children}</li>
  )
}
