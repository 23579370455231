import { type MinMax, type StartEndDate } from '@kijiji/generated/graphql-types'

import { GPT_KEYS } from '@/lib/ads/constants/gpt'
import {
  forSaleByLabel,
  priceLabel,
  singleValueArrayToString,
  yearLabel,
} from '@/lib/ads/utils/gptTargeting'
import { isArrayOfStrings } from '@/types/common'
import { isMinMaxType } from '@/types/search'

// TODO: types will be refactored in the next PR

/**
 * Returns an array containing an object with the price label key-value pair, using the value
 * returned by the `priceLabel` function, if `priceFilters` is defined and is a min-max price filter.
 *
 * @param {MinMax} priceFilters - The price filters to use for the label.
 * @returns {Object[]} An array containing an object with the price label key-value pair, or
 * an empty array if `priceFilters` is not defined or is not a min-max price filter.
 */
export function createPriceLabel(
  priceFilters?: string[] | MinMax | StartEndDate | null | undefined
) {
  if (priceFilters && isMinMaxType(priceFilters)) {
    return {
      key: GPT_KEYS.PRICE_LABEL,
      value: priceLabel(priceFilters),
    }
  }
  return {}
}

/**
 * Returns an array containing an object with the for sale by label key-value pair, using the value
 * returned by the `forSaleByLabel` function, if `forSaleByFilters` is defined and is an array of strings.
 *
 * @param {string[]} forSaleByFilters - The for sale by filters to use for the label.
 * @returns {Object[]} An array containing an object with the for sale by label key-value pair, or
 * an empty array if `forSaleByFilters` is not defined or is not an array of strings.
 */
export function createForSaleByLabel(
  forSaleByFilters?: string[] | MinMax | StartEndDate | null | undefined
) {
  if (forSaleByFilters && isArrayOfStrings(forSaleByFilters)) {
    return {
      key: GPT_KEYS.FOR_SALE_BY_LABEL,
      value: forSaleByLabel(forSaleByFilters),
    }
  }
  return {}
}

/**
 * Returns an array containing an object with the autos year label key-value pair, using the value
 * returned by the `yearLabel` function, if `yearFilters` is defined and is a min-max year filter.
 *
 * @param yearFilters - The year filters to use for the label.
 * @returns {Object[]} An array containing an object with the autos year label key-value pair, or
 * an empty array if `yearFilters` is not defined or is not a min-max year filter.
 */
export function createAutosYearLabel(
  yearFilters?: string[] | MinMax | StartEndDate | null | undefined
) {
  if (yearFilters && isMinMaxType(yearFilters)) {
    return {
      key: GPT_KEYS.AUTOS_YEAR,
      value: yearLabel(yearFilters),
    }
  }
  return {}
}

/**
 * Returns an array containing an object with the autos year label key-value pair, using the value
 * returned by the `yearLabel` function, if `yearFilters` is defined and is a min-max year filter.
 *
 * @param yearFilters - The year filters to use for the label.
 * @returns {Object[]} An array containing an object with the autos year label key-value pair, or
 * an empty array if `yearFilters` is not defined or is not a min-max year filter.
 */
export function createCentralYearLabel(
  yearFilters?: string[] | MinMax | StartEndDate | null | undefined
) {
  if (yearFilters && isMinMaxType(yearFilters)) {
    return {
      key: GPT_KEYS.CENTRAL_YEAR_LABEL,
      value: yearLabel(yearFilters),
    }
  }
  return {}
}

/**
 * Returns an array containing an object with the autos year label key-value pair, using the value
 * returned by the `yearLabel` function, if `yearFilters` is defined and is a min-max year filter.
 *
 * @param modelFilters - The year filters to use for the label.
 * @returns {Object[]} An array containing an object with the autos year label key-value pair, or
 * an empty array if `yearFilters` is not defined or is not a min-max year filter.
 */
export function createCentralModelLabel(modelFilters?: string[] | MinMax | StartEndDate | null) {
  if (modelFilters && isArrayOfStrings(modelFilters)) {
    return {
      key: GPT_KEYS.CENTRAL_MODEL_LABEL,
      value: singleValueArrayToString(modelFilters),
    }
  }
  return {}
}

/**
 * Returns an array containing an object with the central make label key-value pair, using the value
 * returned by the `singleValueArrayToString` function, if `makeFilters` is defined and is an array of strings.
 *
 * @param {string[]} makeFilters - The make filters to use for the label.
 * @returns {Object[]} An array containing an object with the central make label key-value pair, or
 * an empty array if `makeFilters` is not defined or is not an array of strings.
 */
export function createCentralMakeLabel(makeFilters?: string[] | MinMax | StartEndDate | null) {
  if (makeFilters && isArrayOfStrings(makeFilters)) {
    return {
      key: GPT_KEYS.CENTRAL_MAKE_LABEL,
      value: singleValueArrayToString(makeFilters),
    }
  }
  return {}
}

/**
 * Returns an array containing an object with the autos year label key-value pair, using the value
 * returned by the `yearLabel` function, if `yearFilters` is defined and is a min-max year filter.
 *
 * @param modelFilters - The year filters to use for the label.
 * @returns {Object[]} An array containing an object with the autos year label key-value pair, or
 * an empty array if `yearFilters` is not defined or is not a min-max year filter.
 */
export function createAutosModelLabel(modelFilters?: string[] | MinMax | StartEndDate | null) {
  if (modelFilters && isArrayOfStrings(modelFilters)) {
    return {
      key: GPT_KEYS.AUTOS_MODEL,
      value: singleValueArrayToString(modelFilters),
    }
  }
  return {}
}

/**
 * Returns an array containing an object with the autos body label key-value pair, using the value
 * returned by the `singleValueArrayToString` function, if `bodyFilters` is defined and is an array of strings.
 *
 * @param {string[]} bodyFilters - The body type filters to use for the label.
 * @returns {Object[]} An array containing an object with the autos body label key-value pair, or
 * an empty array if `bodyFilters` is not defined or is not an array of strings.
 */
export function createAutosBodyLabel(bodyFilters?: string[] | MinMax | StartEndDate | null) {
  if (bodyFilters && isArrayOfStrings(bodyFilters)) {
    return {
      key: GPT_KEYS.AUTOS_BODY,
      value: singleValueArrayToString(bodyFilters),
    }
  }
  return {}
}
