import { type ImagesListProps, imageSeo } from '@kijiji/seo/configs/imageSeo'
import Script from 'next/script'
import { z } from 'zod'

const listingSchema = z.object({
  imageUrls: z.array(z.string()),
})

export const imageSeoJsonLdPropsSchema = z.object({
  id: z.string(),
  listings: z.array(listingSchema),
})

export type ImageSeoJsonLdProps = z.infer<typeof imageSeoJsonLdPropsSchema>

export const ImageSeoJsonLd = ({ listings, id }: ImageSeoJsonLdProps) => {
  const imagesList: ImagesListProps[] = listings
    // Filter out listings without images or with empty image URLs
    .filter((listing) => listing.imageUrls[0] && listing.imageUrls[0].trim() !== '')
    .map((listing) => ({
      contentUrl: listing.imageUrls[0],
      creditText: 'Kijiji',
    }))

  const imageJSONLd = imageSeo(imagesList)

  return (
    <>
      {imageJSONLd.length > 0 && (
        <Script
          id={id}
          data-testid={id}
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(imageJSONLd) }}
        />
      )}
    </>
  )
}
